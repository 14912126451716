import { TransactionType } from '../../../types'
import { ApiService, App } from '../commons'
import { mapResult } from '../hooks/mapResult'
import { PartnerDashboardSettledTransactions } from '../schemas/types'

export class SettledTransactionService extends ApiService<PartnerDashboardSettledTransactions> {}

declare module '../commons' {
  interface ServiceTypes {
    'settled-transactions': SettledTransactionService
  }
}

export const dashboardSettledTransactions = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'settled-transactions',
    new SettledTransactionService(
      connection.service('partner/dashboard/settled-transactions'),
      app
    )
  )

  app.service('settled-transactions').hooks({
    around: {
      all: [mapResult()],
    },
  })
}
