import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Typography } from '@mui/material'

import { api, QuickInvoice, Transaction } from '@shared/api'
import { ActionModal } from '@shared/components'
import { useNotification } from '@shared/hooks'

const useStyles = tss.withName('UnassignTransactionModal').create(() => ({
  modal: {
    width: '500px',
  },
}))

interface UnassignTransactionModalProps {
  quickInvoice: QuickInvoice
  transaction: Transaction
  open: boolean
  onClose: () => void
  onSuccess: () => void
}

export const UnassignTransactionModal: FC<UnassignTransactionModalProps> = ({
  quickInvoice,
  transaction,
  open,
  onClose,
  onSuccess,
}) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { setNotification } = useNotification()

  const [isLoading, setIsLoading] = useState(false)

  const onUnassign = async () => {
    try {
      setIsLoading(true)
      await api
        .service('quick-invoices')
        .deleteTransaction(quickInvoice.id, transaction.id)

      setNotification({
        label: t('common.transaction-unassigned'),
        type: 'success',
      })

      onSuccess()
    } catch (error) {
      setNotification({
        label: t('common.transaction-unassign-error'),
        type: 'error',
      })
    } finally {
      setIsLoading(false)
      onClose()
    }
  }

  return (
    <ActionModal
      open={open}
      title={t('common.unassign-transaction')}
      buttons={[
        {
          label: t('common.cancel'),
          color: 'secondary',
          onClick: onClose,
          guidingId: 'unassigntransaction-cancel',
        },
        {
          label: t('common.unassign'),
          color: 'primary',
          isLoading: isLoading,
          onClick: onUnassign,
          guidingId: 'unassigntransaction-unassign',
        },
      ]}
      onClose={onClose}
      className={classes.modal}
      guidingId="unassigntransaction"
    >
      <Typography fontSize="14px">
        {`${t('common.modal.are-you-sure')} ${t(
          'common.unassign-this-transaction'
        )}?`}
      </Typography>
    </ActionModal>
  )
}
