import { ApiService, App } from '../../commons'
import { mapResult } from '../../hooks/mapResult'
import { paginationQuery } from '../../hooks/paginationQuery'
import { SalesTax } from '../../schemas/types'

export class SalesTaxService extends ApiService<SalesTax> {}

declare module '../../commons' {
  interface ServiceTypes {
    'sales-taxes': SalesTaxService
  }
}

export const salesTaxes = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'sales-taxes',
    new SalesTaxService(connection.service('sales-taxes'), app)
  )

  app.service('sales-taxes').hooks({
    around: {
      all: [paginationQuery(), mapResult()],
    },
  })
}
