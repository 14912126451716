import { ApiService, App } from '../commons'

export type PasswordReset = {
  id: string
  username: string
  password: string
  terms_condition_code: string
  email: string
  code: string
  newPassword: string
  url: string
}

export type StatusMessage = {
  type: string
  ok: boolean
}

export class PasswordResetService extends ApiService<PasswordReset> {
  async check_code(email: string, code: string) {
    return this.apiRequest<StatusMessage>({
      body: { email, code },
      url: 'password-reset-code',
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  async create_code(email: string) {
    return this.apiRequest<StatusMessage>({
      body: { email },
      url: 'password-reset-code',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  async update_password(email: string, code: string, new_password: string) {
    return this.apiRequest<StatusMessage>({
      body: { email, code, new_password },
      url: 'password-reset-code',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }
}

declare module '../commons' {
  interface ServiceTypes {
    'public/users': PasswordResetService
  }
}

export const passwordResetCode = (app: App) => {
  const connection = app.get('connection')
  app.use(
    'public/users',
    new PasswordResetService(connection.service('public/users'), app)
  )
}
