import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Box, Checkbox, CircularProgress } from '@mui/material'
import FormHelperText from '@mui/material/FormHelperText'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import { Input } from '@shared/components'

const useStyles = tss
  .withName('Select')
  .withParams<{ multiple: boolean }>()
  .create(({ theme, multiple }) => ({
    select: {
      background: '#FFF',
      height: 40,
      width: '100%',
      overflow: 'hidden',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '&:focus': {
        borderRadius: '4px',
      },
      '& .MuiSelect-select': {
        paddingRight: '8px',
        display: 'flex',
        alignItems: 'center',
      },
      '& .Mui-disabled': {
        backgroundColor: theme.palette['neutral-100'],
      },
    },
    renderValue: {
      color: theme.palette['neutral-500'],
      fontSize: '14px',
      fontStyle: 'normal',
      lineHeight: '20px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: 'calc(100% - 32px)',
    },
    menuItem: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
      whiteSpace: 'normal',
      '& .MuiCheckbox-root': {
        padding: 0,
        marginRight: '8px',
        background: '#FFF',
      },
      '&.Mui-selected': {
        backgroundColor: multiple ? 'transparent' : '#f5f5f5',
        '&:hover': {
          backgroundColor: multiple ? 'transparent' : '#f5f5f5',
        },
      },
    },
    checkbox: {
      '& .MuiSvgIcon-root': {
        width: '18px',
        height: '18px',
      },
      '&.Mui-checked': {
        color: theme.palette['primary-color-700'],
      },
      '& .MuiIconButton-label': {
        background: 'var(--white, #FFF)',
      },
    },
    menuItemText: {
      flexGrow: 1,
      textAlign: 'left',
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '20px',
    },
    menuPaper: {
      maxHeight: 250,
      maxWidth: 250,
    },
    helperTextError: {
      marginLeft: '0 !important',
      fontFamily: 'Inter !important',
      fontSize: '12px !important',
      fontWeight: '500 !important',
      lineHeight: '16px !important',
      color: `${theme?.palette['negative']} !important`,
    },
    circularProgress: {
      marginLeft: 'auto',
      marginRight: '8px',
    },
  }))

// TODO: take advantage of the generics
export interface SelectOption<T extends string | object> {
  label: string | number
  value: string | number
}

export interface SelectProps<T extends string | object> {
  label?: string
  placeholder?: string
  options: SelectOption<T>[]
  value: any
  disabled?: boolean
  required?: boolean
  infoText?: string
  onChange: (event: SelectChangeEvent) => void
  multiple?: boolean
  sort?: boolean
  error?: boolean
  helperText?: string
  style?: React.CSSProperties
  testId?: string
  guidingId?: string
  isLoading?: boolean
  isOnAgGrid?: boolean
}

export const SelectComponent = forwardRef(
  <T extends string>(
    {
      label,
      placeholder,
      options,
      value,
      disabled = false,
      required = false,
      infoText,
      onChange,
      multiple = false,
      sort = false,
      error = false,
      helperText,
      style,
      testId,
      guidingId,
      isLoading = false,
      isOnAgGrid = false,
    }: SelectProps<T>,
    ref
  ) => {
    const { t } = useTranslation()
    const { classes } = useStyles({ multiple })

    const valueProp = multiple
      ? Array.isArray(value)
        ? value
        : []
      : value || ''
    const selectStyle = style
      ? { ...style, minWidth: style.minWidth || 'inherit' }
      : undefined

    const sortedOptions = sort
      ? [...options].sort((a, b) => {
          return a.label.toString().localeCompare(b.label.toString())
        })
      : options

    const renderValueMultiple = (selected: any[]) => {
      if (selected.length === 0) {
        return (
          <em className={classes.renderValue}>
            {label || placeholder || t('common.select')}
          </em>
        )
      }

      const firstSelectedLabel =
        options.find((option) => option.value === selected[0])?.label || ''

      const additionalCount =
        selected.length > 1 ? ` (+${selected.length - 1})` : ''
      return (
        <Box className={classes.renderValue}>
          {`${firstSelectedLabel}${additionalCount}`}
        </Box>
      )
    }

    const renderPlaceholder = () => {
      return (
        <em className={classes.renderValue}>
          {placeholder || t('common.select')}
        </em>
      )
    }

    const getRenderValue = () => {
      if (isLoading) {
        return () => (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress size={20} className={classes.circularProgress} />
          </Box>
        )
      }

      if (multiple) {
        return renderValueMultiple
      }

      if (valueProp) {
        return undefined
      }

      return renderPlaceholder
    }

    return (
      <Box>
        {label !== null && (
          <Input.Label
            text={label}
            required={required}
            testId={testId}
            infoText={infoText}
          />
        )}

        <Select
          ref={ref}
          placeholder={placeholder}
          data-testid={testId ?? 'select-component'}
          data-guiding-id={guidingId}
          className={classes.select}
          value={valueProp}
          onChange={onChange}
          disabled={disabled || isLoading}
          multiple={multiple}
          displayEmpty
          renderValue={getRenderValue()}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            PaperProps: {
              className: classes.menuPaper,
            },
            MenuListProps: {
              className: isOnAgGrid ? 'ag-custom-component-popup' : undefined,
            },
          }}
          error={error}
          style={selectStyle}
        >
          {!isLoading &&
            sortedOptions.map((option, index) => (
              <MenuItem
                key={index}
                value={option.value}
                className={classes.menuItem}
              >
                <span className={classes.menuItemText}>{option.label}</span>
                {multiple && (
                  <Checkbox
                    checked={valueProp.includes(option.value)}
                    className={classes.checkbox}
                  />
                )}
              </MenuItem>
            ))}
        </Select>

        {error && (
          <FormHelperText className={classes.helperTextError}>
            {helperText}
          </FormHelperText>
        )}
      </Box>
    )
  }
)
