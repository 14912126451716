import { ApiService, App } from '../commons'
import { expandQuery } from '../hooks/expandQuery'
import { mapResult } from '../hooks/mapResult'
import { paginationQuery } from '../hooks/paginationQuery'
import { LocationUser } from '../schemas/types'

export class LocationUsersService extends ApiService<LocationUser> {}

declare module '../commons' {
  interface ServiceTypes {
    'location-users': LocationUsersService
  }
}

export const locationUsers = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'location-users',
    new LocationUsersService(connection.service('location-users'), app)
  )

  app.service('location-users').hooks({
    around: {
      all: [paginationQuery(), mapResult(), expandQuery('location')],
      find: [],
      get: [],
    },
  })
}
