import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { tss } from 'tss-react/mui'

import { ChevronRight } from '@mui/icons-material'
import { CircularProgress } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import { Button } from '@shared/components'

import { OptionsGrid } from '../../icons/OptionsGrid'

const useStyles = tss.withName('ContextMenu').create({
  menu: {
    '& .MuiPaper-root': {
      minWidth: 150,
      width: 'auto',
    },
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    color: 'var(--gray-700, var(--ag-data-color, #374151))',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
  },
  icon: {
    color: 'inherit',
    fontWeight: 600,
  },
})

type Position = {
  vertical: 'top' | 'center' | 'bottom'
  horizontal: 'left' | 'center' | 'right'
}

interface ContextMenuOption {
  label: string
  action: () => void
  isVisible?: boolean
  guidingId?: string
}

interface ContextMenuProps {
  options: ContextMenuOption[]
  position?: {
    vertical?: 'top' | 'center' | 'bottom'
    horizontal?: 'left' | 'center' | 'right'
  }
  showIcon?: boolean
  iconVariant?: 'horizontal' | 'vertical'
  buttonText?: string
  disabled?: boolean
  loading?: boolean
  guidingId?: string
}

export const ContextMenu = forwardRef((props: ContextMenuProps, ref) => {
  const defaultPosition = { vertical: 'bottom', horizontal: 'left' }
  const { options = [] } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { classes } = useStyles()

  useImperativeHandle(ref, () => ({
    refresh: () => true,
  }))

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  function validatePosition(position: any): Position {
    let validatedPosition: Position = { vertical: 'top', horizontal: 'left' }

    if (['top', 'center', 'bottom'].includes(position.vertical)) {
      validatedPosition.vertical = position.vertical
    }

    if (['left', 'center', 'right'].includes(position.horizontal)) {
      validatedPosition.horizontal = position.horizontal
    }

    return validatedPosition
  }

  const position = validatePosition(props.position || defaultPosition)

  return (
    <div>
      {props.showIcon !== false ? (
        <IconButton
          aria-label="more"
          aria-controls="action-menu"
          aria-haspopup="true"
          onClick={handleClick}
          style={{
            transform:
              props.iconVariant === 'vertical' ? 'rotate(90deg)' : 'none',
          }}
          data-guiding-id={props.guidingId}
        >
          <OptionsGrid component={null} />
        </IconButton>
      ) : (
        <Button
          guidingId={props.guidingId}
          label={props.buttonText}
          disabled={props.loading || props.disabled}
          color="secondary"
          onClick={handleClick}
          icon={
            props.loading ? (
              <CircularProgress size={14} color="inherit" />
            ) : null
          }
        />
      )}
      <Menu
        id="action-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        className={classes.menu}
        anchorOrigin={{
          vertical: position.vertical,
          horizontal: position.horizontal,
        }}
      >
        {options
          .filter((option) => option.isVisible !== false)
          ?.map((option, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                option.action()
                handleClose()
              }}
              className={classes.menuItem}
              data-guiding-id={option.guidingId}
            >
              <span>{option.label}</span>
              <ChevronRight className={classes.icon} />
            </MenuItem>
          ))}
      </Menu>
    </div>
  )
})
