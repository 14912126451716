import { ApiService, App } from '../commons'
import { expandQuery } from '../hooks/expandQuery'
import { mapResult } from '../hooks/mapResult'
import { paginationQuery } from '../hooks/paginationQuery'
import { Merchant } from '../schemas/types'

export class MerchantsService extends ApiService<Merchant> {
  async services(companyId: string, queryString?: string) {
    return this.apiRequest({
      url: `${companyId}/services?${queryString}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }
}

declare module '../commons' {
  interface ServiceTypes {
    merchants: MerchantsService
  }
}

export const merchants = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'merchants',
    new MerchantsService(connection.service('partner/merchants'), app)
  )

  app.service('merchants').hooks({
    around: {
      all: [
        paginationQuery(),
        mapResult(),
        expandQuery('addresses', 'bank_accounts', 'principals', 'agent'),
      ],
      get: [expandQuery('addresses', 'bank_accounts', 'principals', 'agent')],
    },
  })
}
