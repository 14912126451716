import React from 'react'
import { tss } from 'tss-react/mui'

import { Box, Switch, CircularProgress } from '@mui/material'

const useStyles = tss.withName('Toggle').create(({ theme }) => ({
  switch: {
    width: '44px',
    height: '24px',
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(20px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette['network-blue'],
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: theme.palette['network-blue'],
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette['neutral-300'],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: '20px',
      height: '20px',
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette['neutral-300'],
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  },
}))

export interface ToggleProps {
  checked: boolean
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void
  disabled?: boolean
  isLoading?: boolean
  style?: React.CSSProperties
  className?: string
  testId?: string
  guidingId?: string
}

const Toggle: React.FC<ToggleProps> = ({
  checked,
  onChange,
  disabled = false,
  isLoading = false,
  style,
  className,
  testId,
  guidingId,
}) => {
  const { classes } = useStyles()

  return (
    <Box className={className} sx={style}>
      {isLoading ? (
        <CircularProgress size={24} />
      ) : (
        <Switch
          className={classes.switch}
          checked={!!checked}
          onChange={onChange}
          size="medium"
          data-testid={testId}
          disabled={disabled}
          data-guiding-id={guidingId}
        />
      )}
    </Box>
  )
}

export default Toggle
