import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'
export const HelpInfo = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3229_28975)">
          <path
            d="M9.99935 18.3337C5.39685 18.3337 1.66602 14.6028 1.66602 10.0003C1.66602 5.39783 5.39685 1.66699 9.99935 1.66699C14.6018 1.66699 18.3327 5.39783 18.3327 10.0003C18.3327 14.6028 14.6018 18.3337 9.99935 18.3337ZM9.99935 16.667C11.7675 16.667 13.4632 15.9646 14.7134 14.7144C15.9636 13.4641 16.666 11.7684 16.666 10.0003C16.666 8.23222 15.9636 6.53652 14.7134 5.28628C13.4632 4.03604 11.7675 3.33366 9.99935 3.33366C8.23124 3.33366 6.53555 4.03604 5.2853 5.28628C4.03506 6.53652 3.33268 8.23222 3.33268 10.0003C3.33268 11.7684 4.03506 13.4641 5.2853 14.7144C6.53555 15.9646 8.23124 16.667 9.99935 16.667ZM9.16602 5.83366H10.8327V7.50033H9.16602V5.83366ZM9.16602 9.16699H10.8327V14.167H9.16602V9.16699Z"
            fill="#9CA3AF"
          />
        </g>
        <defs>
          <clipPath id="clip0_3229_28975">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}
