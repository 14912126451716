import React from 'react'
import { tss } from 'tss-react/mui'

interface CustomMessageProps {
  message: string
  IconComponent?: React.ElementType
  showIcon?: boolean
  iconSize?: string
  containerStyle?: React.CSSProperties
  iconStyle?: React.CSSProperties
  textStyle?: React.CSSProperties
}

const useStyles = tss
  .withName('customMessage')
  .withParams<
    Pick<CustomMessageProps, 'iconSize' | 'containerStyle' | 'message'>
  >()
  .create(({ theme, iconSize }) => ({
    container: {
      backgroundColor: '#E5E7EB',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
      height: '100%',
      minHeight: '44px',
      fontSize: '0.75rem',
      borderRadius: '0.25rem',
      maxWidth: '100%',
      flexWrap: 'wrap',
      padding: '16px',
      marginBottom: '24px',
    },
    text: {
      flex: 1,
      maxWidth: 'calc(100% - 20px)',
      wordWrap: 'break-word',
      color: '#111827',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      margin: '0px',
    },
    icon: {
      width: iconSize,
      height: iconSize,
      borderRadius: '50%',
      color: theme.palette.grey[500],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0 8px 0 0',
    },
  }))

const CustomMessage: React.FC<CustomMessageProps> = ({
  message,
  showIcon = false,
  IconComponent,
  iconSize = '16px',
  containerStyle = {},
  iconStyle = {},
  textStyle = {},
}) => {
  const { classes } = useStyles({
    iconSize,
    containerStyle,
    message: '',
  })

  return (
    <div className={classes.container} style={containerStyle}>
      {showIcon && IconComponent ? (
        <IconComponent className={classes.icon} style={iconStyle} />
      ) : null}
      <p className={classes.text} style={textStyle}>
        {message}
      </p>
    </div>
  )
}

export default CustomMessage
