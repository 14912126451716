export enum TransactionAccountType {
  'AMEX' = 'AMEX',
  'CHECKING' = 'Checking',
  'PERSONAL CHECKING' = 'Personal Checking',
  'BUSINESS CHECKING' = 'Business Checking',
  'PERSONAL SAVINGS' = 'Personal Savings',
  'BUSINESS SAVINGS' = 'Business Savings',
  'DEBIT' = 'Debit',
  'DINERS' = 'Diners',
  'DISC' = 'Discover',
  'JCB' = 'JCB',
  'MC' = 'Mastercard',
  'SAVINGS' = 'Savings',
  'VISA' = 'Visa',
  'CASH' = 'Cash',
}
