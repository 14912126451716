import { ApiService, App } from '../../commons'
import { mapResult } from '../../hooks/mapResult'
import { ACHReject } from '../../schemas/types'

export class AchRjectsService extends ApiService<ACHReject> {}

declare module '../../commons' {
  interface ServiceTypes {
    'ach-rejects': AchRjectsService
  }
}

export const merchantPortalDashboardAchRejects = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'ach-rejects',
    new AchRjectsService(
      connection.service('reporting/summary/transactions/ach-reject'),
      app
    )
  )
  app.service('ach-rejects').hooks({
    around: {
      all: [mapResult()],
    },
  })
}
