import { ApiService, App } from '../../commons'
// import { Agents } from '../../schemas/types'

// TODO: add responsePartnerAgentsColletion to the oas file when available
export class AgentsService extends ApiService<any> {
  async getOfficeAgents(officeId: string) {
    const agents = await this.apiRequest<any>({
      body: {},
      url: `${officeId}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return agents.list
  }
}

declare module '../../commons' {
  interface ServiceTypes {
    agents: any
  }
}

export const partnerPortalAgents = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'agents',
    new AgentsService(connection.service('partner/agents'), app),
    {}
  )
}
