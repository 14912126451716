import { ApiService, App } from '../commons'
import { mapResult } from '../hooks/mapResult'
import { ProductRecurring } from '../schemas/types'

export class ProductRecurringsSerivce extends ApiService<ProductRecurring> {
  async update(id: string, payload: Partial<ProductRecurring>) {
    return this.apiRequest<ProductRecurring>({
      body: payload,
      url: `${id}`,
      method: 'PATCH',
    })
  }
}

declare module '../commons' {
  interface ServiceTypes {
    'product-recurrings': ProductRecurringsSerivce
  }
}

export const productRecurring = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'product-recurrings',
    new ProductRecurringsSerivce(connection.service('product-recurrings'), app)
  )

  app.service('product-recurrings').hooks({
    around: {
      all: [mapResult()],
    },
  })
}
