import React from 'react'
import { CSSObject } from 'tss-react'
import { tss } from 'tss-react/mui'

import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'

import { ButtonProps, Button } from '@shared/components'

type StyleParams = {
  position: ActionModalPosition
}

const useStyles = tss
  .withName('ActionModal')
  .withParams<StyleParams>()
  .create(({ theme, position }) => {
    const paperPosition: CSSObject = {
      position: 'absolute',
      top: position === 'top' ? '0px' : '50%',
      left: '50%',
      transform: `translate(-50%, ${position === 'top' ? '0' : '-50%'})`,
    }

    return {
      root: { height: 'auto' },
      paper: {
        ...paperPosition,
        width: '400px',
        borderRadius: '6px',
      },
      container: { padding: '20px' },
      titleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      contentContainer: {
        padding: 0,
        margin: '24px 0 24px 0',
        maxHeight: '400px',
      },
      contentContainerScrollable: {
        padding: 0,
        margin: '24px 0 24px 0',
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: 'calc(100vh - 230px)',
      },
      buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 0,
      },
      title: {
        padding: 0,
        fontFamily: 'Inter',
        fontSize: '18px',
        fontWeight: '600',
        lineHeight: '28px',
        color: theme.palette['neutral-900'],
      },
      subtitle: {
        color: theme.palette['neutral-700'],
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '20px',
      },
      description: {
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '20px',
        color: theme.palette['neutral-700'],
      },
      button: { width: '48%' },
    }
  })

type ActionModalPosition = 'top' | 'center'

export interface ActionModalProps {
  /**
   * If `true`, the Modal will show.
   */
  open: boolean
  /**
   * The title to display.
   */
  title: string
  /**
   * The subtitle to display.
   */
  subtitle?: string
  /**
   * The Action Buttons to display.
   */
  buttons?: [ButtonProps] | [ButtonProps, ButtonProps]
  /**
   * Determines the Modal position, it can be either `top` or `center`.
   *
   * If a more customized position is needed, it can be done through the `className` styles.
   *
   * Defaults to `top`.
   */
  position?: ActionModalPosition
  /**
   * A className to extend the Modal styles.
   */
  className?: string
  /**
   * Callback that gets fired to close the Modal.
   */
  onClose?: React.MouseEventHandler<HTMLButtonElement>
  /**
   * If `true`, the Modal will adjust its content area to support scrolling content.
   */
  isScrollableContent?: boolean
  /**
   * If `true`, the close button will be disabled.
   */
  isCloseDisabled?: boolean

  guidingId?: string
}

export const ActionModal: React.FC<ActionModalProps> = ({
  open,
  title,
  subtitle,
  children,
  buttons,
  position = 'top',
  className,
  onClose,
  isScrollableContent,
  isCloseDisabled = false,
  guidingId,
}) => {
  const { classes, cx } = useStyles({ position })

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ container: classes.root, paper: cx(classes.paper, className) }}
      data-testid="action-modal"
      data-guiding-id={`${guidingId}-action-modal`}
    >
      <Box className={classes.container}>
        <Box className={classes.titleContainer}>
          <DialogTitle
            className={classes.title}
            data-testid="action-modal-title"
          >
            {title}
          </DialogTitle>

          <IconButton
            aria-label="close"
            onClick={onClose}
            disabled={isCloseDisabled}
            data-guiding-id={`${guidingId}-action-modal-close`}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {subtitle && (
          <Typography
            className={classes.subtitle}
            data-testid="action-modal-subtitle"
          >
            {subtitle}
          </Typography>
        )}

        <DialogContent
          className={`${
            isScrollableContent
              ? classes.contentContainerScrollable
              : classes.contentContainer
          }`}
          data-testid="action-modal-content"
        >
          {typeof children === 'string' ? (
            <DialogContentText className={classes.description}>
              {children}
            </DialogContentText>
          ) : (
            children
          )}
        </DialogContent>

        {buttons && buttons.length > 0 && (
          <DialogActions className={classes.buttonsContainer}>
            {buttons.map((props, index) => (
              <Button
                key={`action-modal-button-${index}`}
                className={classes.button}
                {...props}
                guidingId={props.guidingId}
              />
            ))}
          </DialogActions>
        )}
      </Box>
    </Dialog>
  )
}
