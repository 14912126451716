import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { ContentCopy } from '@mui/icons-material'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'

import appTheme from '@shared/design'

const useStyles = tss.withName('CopyToClipboard').create(({ theme }) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  message: {
    color: theme.palette['neutral-900'],
    fontSize: '14px',
  },
  button: {
    color: theme.palette['primary-700'],
    transition: 'color 0.3s',
    marginLeft: '12px',
  },
}))

type CopyToClipboardProps = {
  text: string
  isClipboardIconShown?: boolean
  textTestid?: string
}

const CopyToClipboard = ({
  text,
  isClipboardIconShown = false,
  textTestid,
}: CopyToClipboardProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles(appTheme)

  const [isCopied, setIsCopied] = useState(false)

  async function copyTextToClipboard(text: string) {
    return await navigator.clipboard.writeText(text)
  }

  const handleCopyClick = () => {
    copyTextToClipboard(text).then(() => {
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, 1500)
    })
  }

  return (
    <Box className={classes.wrapper}>
      <Typography variant="body1" data-testid={textTestid}>
        {text}
      </Typography>

      {isClipboardIconShown && (
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          open={isCopied}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={t('common.copied')}
          placement="right-start"
        >
          <IconButton
            type="button"
            onClick={handleCopyClick}
            className={classes.button}
            data-testid="copy-to-clipboard-button"
            data-guiding-id="copy-to-clipboard"
          >
            <ContentCopy />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  )
}

export default CopyToClipboard
