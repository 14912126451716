export enum AvsResponseType {
  'BAD' = 'BAD',
  'GOOD' = 'GOOD',
  'STREET' = 'STREET',
  'ZIP' = 'ZIP',
  'UNKNOWN' = 'UNKNOWN',
}

export const avsResponses: Record<Lowercase<AvsResponseType>, string> = {
  bad: `${AvsResponseType.BAD} - Both street and zip do not match`,
  good: `${AvsResponseType.GOOD} - Street or zip are both good (if provided)`,
  street: `${AvsResponseType.STREET} - Street does not match`,
  zip: `${AvsResponseType.ZIP} - Zip does not match`,
  unknown: AvsResponseType.UNKNOWN,
}
