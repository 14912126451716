import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react'

import { User } from '../api'
import {
  UserPermissionSet,
  createUserPermissionSet,
} from '../utils/permissions/permissions'

interface AuthorizationData {
  userPermissionSet: UserPermissionSet
}

const AuthorizationContext = createContext<AuthorizationData | undefined>(
  undefined
)

interface AuthorizationProviderProps {
  user: User
  children: ReactNode
}

export const AuthorizationProvider: React.FC<AuthorizationProviderProps> = ({
  user,
  children,
}) => {
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false)
  const [userPermissionSet, setUserPermissionSet] = useState<UserPermissionSet>(
    new Set<string>()
  )

  useEffect(() => {
    if (!user) {
      setUserPermissionSet(new Set<string>())
      return
    }

    setUserPermissionSet(createUserPermissionSet(user))
  }, [user])

  const contextValue: AuthorizationData = {
    userPermissionSet,
  }

  return (
    <AuthorizationContext.Provider value={contextValue}>
      {children}
    </AuthorizationContext.Provider>
  )
}

// Define a custom hook to use the context
export const useAuthorization = (): AuthorizationData => {
  const context = useContext(AuthorizationContext)

  if (!context) {
    throw new Error(
      'useAuthorization must be used within a AuthorizationProvider'
    )
  }

  return context
}
