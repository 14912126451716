import { ApiService, App } from '../commons'

export type UserVerification = {
  password: string
  user_verification_id: string
  hash: string
}

export class UserVerificationService extends ApiService<UserVerification> {
  async verify(user_verification_id: string, hash: string, password: string) {
    return this.apiRequest<UserVerification>({
      body: { user_verification_id, hash, password },
      method: 'POST',
      url: '',
    })
  }
}

declare module '../commons' {
  interface ServiceTypes {
    'public/user-verification': UserVerificationService
  }
}

export const userVerification = (app: App) => {
  const connection = app.get('connection')
  app.use(
    'public/user-verification',
    new UserVerificationService(
      connection.service('public/user-verification'),
      app
    )
  )
}
