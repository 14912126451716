import React, { forwardRef, useState } from 'react'
import MaskedInput from 'react-text-mask'
import { tss } from 'tss-react/mui'

import { Popover } from '@mui/material'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import { Input } from '@shared/components'
import { dateMask, formatRawDate } from '@shared/utils'

import Calendar from '../../../icons/Calendar'

const useStyles = tss.withName('DatePicker').create(({ theme }) => ({
  disabledInputDate: {
    '&:hover': {
      cursor: 'default',
    },
  },
}))

export interface DatePickerProps {
  label: string
  value?: string
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>
  error?: boolean
  helperText?: string
  required?: boolean
  disabled?: boolean
  style?: React.CSSProperties
  testId?: string
  disablePast?: boolean
  inputRef?: React.Ref<any>
  guidingId?: string
}

const DatePicker = forwardRef<unknown, DatePickerProps>(
  (
    {
      label,
      value,
      onChange,
      onBlur,
      error = false,
      helperText,
      required = false,
      disabled = false,
      style,
      testId,
      disablePast = false,
      guidingId,
    },
    ref
  ) => {
    const [openCalendar, setOpenCalendar] = useState(false)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const { classes } = useStyles()

    const handleCalendarClick = (e) => {
      if (disabled) return
      setAnchorEl(e.currentTarget as HTMLElement)
      setOpenCalendar(true)
    }

    const handleCalendarChange = (value) => {
      const dateFormatted = formatRawDate(value.ts, 'MM/dd/yyyy')
      onChange({
        target: {
          value: dateFormatted,
        },
      } as React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)
    }

    return (
      <>
        <Popover
          open={openCalendar}
          anchorEl={anchorEl}
          onClose={() => setOpenCalendar(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DateCalendar
              disablePast={disablePast}
              onChange={handleCalendarChange}
              disabled={disabled}
            />
          </LocalizationProvider>
        </Popover>

        <Input
          ref={ref}
          label={label}
          value={value}
          mask={DateMaskInput}
          onChange={onChange}
          onBlur={onBlur}
          error={error}
          helperText={helperText}
          icon={{
            component: (
              <Calendar className={disabled ? classes.disabledInputDate : ''} />
            ),
            onClick: handleCalendarClick,
          }}
          required={required}
          disabled={disabled}
          style={style}
          testId={testId}
          guidingId={guidingId}
        />
      </>
    )
  }
)

const DateMaskInput = React.forwardRef((props, ref) => {
  const { ...other } = props
  return <MaskedInput {...other} mask={dateMask} placeholder="MM/DD/YYYY" />
})

export default DatePicker
