import { ApiService, App } from '../../commons'
import { expandQuery } from '../../hooks/expandQuery'
import { mapResult } from '../../hooks/mapResult'
import { paginationQuery } from '../../hooks/paginationQuery'
import { Transaction } from '../../schemas/types'

export class RecurringBillingDeclinesService extends ApiService<Transaction> {}

declare module '../../commons' {
  interface ServiceTypes {
    'recurring-billing-declines-reports': RecurringBillingDeclinesService
  }
}

export const recurringBillingDeclinesReports = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'recurring-billing-declines-reports',
    new RecurringBillingDeclinesService(
      connection.service('transactions'),
      app
    ),
    {
      methods: ['export'],
    }
  )

  app.service('recurring-billing-declines-reports').hooks({
    around: {
      all: [paginationQuery(), mapResult()],
      find: [expandQuery('recurring', 'product_transaction')],
      export: [expandQuery('recurring', 'product_transaction')],
    },
  })
}
