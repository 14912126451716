export type GatewayTransactionEntryModeKey =
  | 'B'
  | 'C'
  | 'S'
  | 'K'
  | 'P'
  | 'F'
  | 'U'

export const gatewayTransactionsEntryModes: Record<
  GatewayTransactionEntryModeKey,
  string
> = {
  B: 'Bar Code',
  C: 'Chip Card',
  S: 'Swiped',
  K: 'Keyed',
  P: 'Contactless',
  F: 'Fallback',
  U: 'Unknown',
}
