import { ApiService, App } from '../../commons'
import { mapResult } from '../../hooks/mapResult'
import { TokenImportDetails } from '../../schemas/types'

export class TokenImportDetailsService extends ApiService<TokenImportDetails> {}
declare module '../../commons' {
  interface ServiceTypes {
    'token-import-details': TokenImportDetailsService
  }
}
export const tokenImportDetails = (app: App) => {
  const connection = app.get('connection')
  app.use(
    'token-import-details',
    new TokenImportDetailsService(
      connection.service('merchants/token-import-details'),
      app
    )
  )
  app.service('token-import-details').hooks({
    around: {
      all: [mapResult()],
    },
  })
}
