import { ApiService, App } from '../../commons'
import { mapResult } from '../../hooks/mapResult'

export class FilesService extends ApiService<any> {
  async getFileAttachment(fileId: string) {
    const attachment = await this.apiRequest<any>({
      url: `${fileId}/attachment`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return attachment
  }
}

declare module '../../commons' {
  interface ServiceTypes {
    files: FilesService
  }
}

export const merchantPortalFiles = (app: App) => {
  const connection = app.get('connection')

  app.use('files', new FilesService(connection.service('files'), app))

  app.service('files').hooks({
    around: {
      all: [mapResult()],
    },
  })
}
