import { NextFunction } from '@feathersjs/feathers'

import { HookContext } from '../commons'
import { MerchantAccount } from '../schemas/types'

export const filterResults =
  () => async (context: HookContext, next: NextFunction) => {
    await next()

    // Remove any object that has "industry_type.lodging", consider expands.
    if (context.result) {
      if (Array.isArray(context.result)) {
        const firstItem = context.result[0] || {}
        if (firstItem.hasOwnProperty('industry_type')) {
          context.result = context.result.filter(
            ({ industry_type }: MerchantAccount) => industry_type !== 'lodging'
          )
        }

        if (firstItem.hasOwnProperty('product_transactions')) {
          context.result = context.results.map((result: any) => ({
            ...result,
            product_transactions: result.product_transactions?.filter(
              ({ industry_type }: MerchantAccount) =>
                industry_type !== 'lodging'
            ),
          }))
        }
      } else {
        if (context.result.hasOwnProperty('product_transactions')) {
          context.result.product_transactions =
            context.result.product_transactions?.filter(
              ({ industry_type }: MerchantAccount) =>
                industry_type !== 'lodging'
            )
        }
      }
    }
  }
