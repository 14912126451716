import { ApiService, App } from '../commons'
import { mapResult } from '../hooks/mapResult'
import { paginationQuery } from '../hooks/paginationQuery'
import { AuthRoleUser } from '../schemas/types'

export class AuthRoleUsersService extends ApiService<AuthRoleUser> {}

declare module '../commons' {
  interface ServiceTypes {
    'auth-role-users': AuthRoleUsersService
  }
}

export const authRoleUsers = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'auth-role-users',
    new AuthRoleUsersService(connection.service('auth-role-users'), app)
  )

  app.service('auth-role-users').hooks({
    around: {
      all: [paginationQuery(), mapResult()],
      find: [],
      get: [],
    },
  })
}
