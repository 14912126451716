import { useEffect, useState } from 'react'
import { tss } from 'tss-react/mui'

import { Box, Button, Typography } from '@mui/material'

import { Input, InputProps, useInputStyles } from '@shared/components'
import { RadioButtonFilled, RadioButtonOutlined } from '@shared/icons'

const useStyles = tss.withName('RadioButtons').create(({ theme }) => ({
  root: {
    display: 'grid',
  },
  buttonsContainer: {
    display: 'flex',
    minHeight: '40px',
    gap: '8px',
  },
  label: {
    display: 'block',
    margin: '0 1em',
  },
  button: {
    height: 'auto',
    width: 'auto',
    fontFamily: 'Inter !important',
    fontSize: '14px !important',
    fontWeight: '500 !important',
    lineHeight: '20px !important',
    textTransform: 'none',
    padding: '9px',
    maxWidth: '50%',
  },
  primary: {
    backgroundColor: `${theme?.palette['network-blue']} !important`,
    color: 'white !important',
  },
  secondary: {
    backgroundColor: `${theme?.palette['white']} !important`,
    color: `${theme?.palette['neutral-700']} !important`,
    border: `1px solid ${theme?.palette['neutral-300']} !important`,
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05) !important',
  },
  disabledButton: {
    backgroundColor: `${theme?.palette['neutral-300']} !important`,
    color: `${theme?.palette['neutral-400']} !important`,
  },
  textAlignLeft: {
    textAlign: 'left',
  },
  selected: {
    backgroundColor: `${theme?.palette['neutral-50']} !important`,
    color: 'black !important',
    border: `1.5px solid ${theme?.palette['power-orange']} !important`,
  },
}))

export type RadioButtonsArray = {
  title: string
  description?: string
  visible?: boolean
  color: 'primary' | 'secondary'
  defaultSelected: boolean
  disabled?: boolean
  testId?: string
  guidingId?: string
  className?: string
  style?: React.CSSProperties
  onClick: () => void
}[]

interface RadioButtonProps extends Pick<InputProps, 'error' | 'helperText'> {
  label?: string
  required?: boolean
  buttons: RadioButtonsArray
  testId?: string
}

export function RadioButtons({
  testId,
  label,
  required,
  buttons,
  error,
  helperText,
}: RadioButtonProps) {
  const { classes, cx } = useStyles()
  const { classes: inputClasses } = useInputStyles()
  const [selectedButton, setSelectedButton] = useState<number | null>(null)

  const handleClick = (index: number, onClick: () => void) => {
    setSelectedButton(index)
    onClick()
  }

  useEffect(() => {
    const defaultSelectedButtonIndex = buttons.findIndex(
      ({ defaultSelected }) => !!defaultSelected
    )

    if (defaultSelectedButtonIndex !== -1) {
      setSelectedButton(defaultSelectedButtonIndex)
    } else {
      setSelectedButton(undefined)
    }
  }, [buttons])

  return (
    <Box className={classes.root}>
      <Input.Label text={label} required={required} testId={testId} />

      <Box className={classes.buttonsContainer} data-testid={testId}>
        {buttons.map((button, index) => {
          if (button.visible !== undefined && !button.visible) return null
          return (
            <Button
              data-guiding-id={button.guidingId}
              data-testid={button.testId}
              className={cx(
                `${classes.button} ${
                  button.color === 'secondary'
                    ? classes.secondary
                    : classes.primary
                } ${button.disabled ? classes.disabledButton : ''} ${
                  button.description ? classes.textAlignLeft : ''
                } ${selectedButton === index ? classes.selected : ''}`,
                button.className
              )}
              disabled={button.disabled}
              type="button"
              onClick={() => handleClick(index, button.onClick)}
              key={index}
              style={button.style}
            >
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {selectedButton === index ? (
                    <RadioButtonFilled />
                  ) : (
                    <RadioButtonOutlined />
                  )}

                  <span className={classes.label}>{button.title}</span>
                </Box>

                {button.description && (
                  <span
                    className={classes.label}
                    style={{ color: '#aaa', fontSize: '12px' }}
                  >
                    {button.description}
                  </span>
                )}
              </Box>
            </Button>
          )
        })}
      </Box>

      {error && helperText && (
        <Typography
          id={`${testId}-helper`}
          className={cx(inputClasses.helperText, inputClasses.helperTextError)}
        >
          {helperText}
        </Typography>
      )}
    </Box>
  )
}
export default RadioButtons
