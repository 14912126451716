export const settledTransactionTypes = [
  { code: 'OTHER', description: 'Other' },
  { code: 'SALE', description: 'Sale' },
  { code: 'CREDIT', description: 'Credit' },
  { code: 'CASH ADV', description: 'Cash advance' },
  { code: 'COND DEPOSIT SALE', description: 'Conditional deposit sale' },
  {
    code: 'COND DEPOSIT CASH ADV',
    description: 'Conditional deposit cash advance',
  },
  { code: 'AUTH', description: 'Authorization request' },
  { code: 'RETURN REREVERSAL', description: 'Rereversal return' },
  { code: 'SALE REVERSAL', description: 'Reversal sale' },
  { code: 'SALE REREVERSAL', description: 'Rereversal sale' },
  {
    code: 'UN-EIRF-CASH',
    description: 'Un-electronic interchange reimbursement',
  },
  {
    code: 'UN-EIRF-SALE',
    description: 'Un-electronic interchange reimbursement',
  },
  { code: 'CASH ADV AUTH', description: 'Cash advance authorization request' },
  { code: 'AMEX AUTH', description: 'American Express authorization request' },
  {
    code: 'CARTE BLANCHE AUTH',
    description: 'Carte Blanche authorization request',
  },
  {
    code: 'DINERS CLUB AUTH',
    description: 'Diners Club authorization request',
  },
  { code: 'JBS AUTH', description: 'JBS Auth Request' },
  { code: 'PRIVATE LABEL AUTH', description: 'Private Label Auth Request' },
  { code: 'AVS', description: 'Address verification request' },
  { code: 'VOID', description: 'Regular Void' },
  { code: 'VOID UNDELIVERED', description: 'Void Undelivered' },
  { code: 'VOID REVERSAL', description: 'Void Reversal' },
  { code: 'COND DEPOSIT', description: 'Conditional deposit' },
  { code: 'DEPOSIT', description: 'Deposit' },
  { code: 'UNKNOWN', description: 'Exception File Change' },
  { code: 'FUEL AUTH', description: 'Fuel Pre-Authorizations' },
  { code: 'FUEL SALE', description: 'Fleet Card Sale' },
  { code: 'INQUIRY', description: 'Inquiry' },
  { code: 'MAIL PHONE ORDER', description: 'Mail/Telephone Order' },
  { code: 'PARTIAL REVERSAL', description: 'Partial Reversal' },
  { code: 'PAYMENT', description: 'Payment on Account' },
  { code: 'POS CREDIT', description: 'POS Credit' },
  { code: 'POS DEBIT', description: 'POS Debit' },
  { code: 'PURCHASE REVERSAL', description: 'Purchase Reversal' },
  { code: 'REFUND', description: 'Refund' },
  { code: 'CASH ADJUSTMENT', description: 'Cash adjustment' },
  { code: 'CASH REVERSAL', description: 'Reversal cash' },
  { code: 'CASH REREVERSAL', description: 'Rereversal cash' },
  { code: 'DELINQUENT', description: 'Delinquent' },
  { code: 'OVERLIMIT', description: 'Overlimit' },
  { code: 'PK_1RETURN', description: 'Return' },
  { code: 'RETURN REVERSAL', description: 'Reversal return' },
]
