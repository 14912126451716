import React, { ChangeEvent, useEffect, useState } from 'react'
import { tss } from 'tss-react/mui'

import { Box, SxProps, Theme, Typography } from '@mui/material'

import { Button, ButtonProps, Toggle } from '@shared/components'

const useStyles = tss.withName('FieldGroupContainer').create(({ theme }) => ({
  container: {
    marginBottom: '24px',
  },
  titleArea: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '12px',
  },
  titleAreaHover: {
    ':hover': {
      cursor: 'pointer',
    },
  },
  title: {
    color: theme.palette['neutral-700'],
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '28px',
    marginBottom: '0px',
  },
  titleSubContainer: {
    color: theme.palette['neutral-700'],
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '28px',
    marginBottom: '0px',
  },
  description: {
    color: theme.palette['neutral-500'],
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    marginTop: '0px',
    marginRight: '25px',
  },
}))

export interface FieldGroupContainerProps {
  style?: SxProps<Theme>
  className?: string
  title?: string
  description?: string
  titleTestID?: string
  toggleTestID?: string
  showToggle?: boolean
  showContent?: boolean
  forceOpen?: boolean
  buttonData?: ButtonProps
  titleDecorator?: React.ReactNode
  isSubContainer?: boolean
  guidingId?: string
}

export const FieldGroupContainer: React.FC<FieldGroupContainerProps> = ({
  children,
  style,
  className,
  title,
  description,
  titleTestID,
  toggleTestID = 'toggle-input',
  showToggle = false,
  showContent = true,
  forceOpen = false,
  buttonData,
  titleDecorator,
  isSubContainer = false,
  guidingId,
}) => {
  const { classes, cx } = useStyles()

  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    if (forceOpen) setOpen(forceOpen)
    else setOpen(showContent)
  }, [forceOpen, showContent])

  const onToggleAdvancedSettings = (
    _: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => setOpen(checked)

  const toggleArea = () => {
    if (!showToggle) return
    setOpen(!open)
  }

  const applyTitleAreaClass =
    title || showToggle || titleDecorator || description

  return (
    <Box sx={style} className={cx(classes.container, className)}>
      <Box
        onClick={toggleArea}
        className={`${applyTitleAreaClass ? classes.titleArea : ''} ${
          showToggle ? classes.titleAreaHover : ''
        }`}
      >
        {(title || showToggle) && (
          <Box display="flex" gap="12px">
            <Box>
              <Typography
                className={`${
                  isSubContainer ? classes.titleSubContainer : classes.title
                }`}
                data-testid={titleTestID}
              >
                {title}
              </Typography>
              {titleDecorator}
              {description && (
                <Typography className={classes.description}>
                  {description}
                </Typography>
              )}
            </Box>
          </Box>
        )}
        {buttonData && <Button {...buttonData} />}
        {showToggle && (
          <Toggle
            checked={open}
            onChange={onToggleAdvancedSettings}
            testId={toggleTestID}
            guidingId={`${guidingId}-toggle`}
          />
        )}
      </Box>
      {open && <Box>{children}</Box>}
    </Box>
  )
}
