import React from 'react'
import { tss } from 'tss-react/mui'

import { Grid, Typography } from '@mui/material'

const useStyles = tss
  .withName('FieldGroupViewItemWithChildren')
  .create(({ theme }) => ({
    title: {
      color: theme.palette['neutral-600'],
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
    },
    subtitle: {
      color: theme.palette['neutral-500'],
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '20px',
    },
  }))

const EMPTY_FIELD_PLACEHOLDER = '-'

export interface FieldGroupViewItemWithChildrenProps {
  title: string
  titleTestID?: string
  subtitle?: string
  subtitleTestID?: string
  isFullWidth?: boolean
  isValueAlignRight?: boolean
  isVerticallyCentered?: boolean
}

export const FieldGroupViewItemWithChildren: React.FC<
  FieldGroupViewItemWithChildrenProps
> = ({
  children,
  title,
  titleTestID = 'field-group-item-children-view-title',
  subtitle,
  subtitleTestID = 'field-group-item-children-view-subtitle',
  isFullWidth = false,
  isValueAlignRight = false,
  isVerticallyCentered = false,
}) => {
  const { classes } = useStyles()

  return (
    <Grid item xs={12} sm={isFullWidth ? 12 : 6}>
      <Grid
        container
        alignItems={isVerticallyCentered ? 'center' : 'flex-start'}
      >
        <Grid item md={12} lg={6}>
          <Typography className={classes.title} data-testid={titleTestID}>
            {title}
          </Typography>
          {subtitle && (
            <Typography
              className={classes.subtitle}
              data-testid={subtitleTestID}
            >
              {subtitle}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          md={12}
          lg={6}
          textAlign={isValueAlignRight ? 'right' : 'left'}
        >
          {children}
        </Grid>
      </Grid>
    </Grid>
  )
}
