import { ApiService, App } from '../../commons'
import { mapResult } from '../../hooks/mapResult'
import { Tags } from '../../schemas/types'

export class TagsService extends ApiService<Tags> {}

declare module '../../commons' {
  interface ServiceTypes {
    tags: TagsService
  }
}

export const tags = (app: App) => {
  const connection = app.get('connection')

  app.use('tags', new TagsService(connection.service('tags'), app))

  app.service('tags').hooks({
    around: {
      all: [mapResult()],
    },
  })
}
