import { MerchantAccount, api } from '../../api/src'
import { preprocessCurrencyAmount } from '../../api/src/utils/transactions/preprocessCurrencyAmount'

export const calculateSurcharge = async (
  merchantAccount: MerchantAccount,
  subtotal_amount: number,
  tax: number,
  tip_amount: number,
  tokenId?: string,
  postal_code?: string,
  account_number?: string
) => {
  if (!merchantAccount?.id) return
  try {
    const {
      subtotal_amount: subtotal,
      surcharge_amount,
      transaction_amount,
      tax_amount,
      tip_amount: tip,
      surcharge_applied,
    } = await api.service('calculate-surcharge').create({
      account_number,
      token_id: tokenId,
      subtotal_amount: preprocessCurrencyAmount(subtotal_amount),
      tax_amount: preprocessCurrencyAmount(tax),
      tip_amount: preprocessCurrencyAmount(tip_amount),
      zip: merchantAccount.surcharge?.state_exception_check
        ? postal_code
        : undefined,
      product_transaction_id: merchantAccount.id,
    })

    return {
      subtotal_amount: subtotal,
      surcharge_amount,
      transaction_amount,
      tax: tax_amount,
      tip_amount: tip,
      surcharge_applied: !!surcharge_applied,
    }
  } catch (error) {
    return {
      subtotal_amount,
      surcharge_amount: 0,
      transaction_amount: subtotal_amount,
      tax,
      tip_amount,
      surcharge_applied: false,
    }
  }
}
