import { createContext, ReactNode, useContext } from 'react'

import { EnumServiceName } from '@shared/types'

import { transformFiltersForStorage } from '../utils/transform-filters-storage/transformFiltersForStorage'

// Use isDetails as a hacky solution to handle filters for details page such as BatchDetails.tsx
type ReportFiltersContextState = {
  setFilter: (report: EnumServiceName, filter: any, isDetails?: boolean) => void
  getFilter: (report: EnumServiceName, isDetails?: boolean) => any
  clearFilter: (report: EnumServiceName, isDetails?: boolean) => void
  resetAllFilters: () => void
}

type ReportFiltersContextProps = {
  children: ReactNode
}

const ReportFiltersContext = createContext<ReportFiltersContextState>(
  {} as ReportFiltersContextState
)

export const ReportFiltersProvider = ({
  children,
}: ReportFiltersContextProps) => {
  const setFilter = (report, filter, isDetails = false) => {
    const reportName = `${report}${isDetails ? '-details' : ''}`
    const transformedFilter = transformFiltersForStorage(filter)
    localStorage.setItem(reportName, JSON.stringify(transformedFilter))
  }

  const getFilter = (report: EnumServiceName, isDetails = false) => {
    const reportName = `${report}${isDetails ? '-details' : ''}`
    const reportStorageFilter = localStorage.getItem(reportName)

    if (reportStorageFilter) {
      return JSON.parse(reportStorageFilter)
    }

    return {}
  }

  const clearFilter = (report: EnumServiceName, isDetails = false) => {
    const reportName = `${report}${isDetails ? '-details' : ''}`
    localStorage.removeItem(reportName)
  }

  const resetAllFilters = () => {
    Object.values(EnumServiceName).forEach((item) => {
      localStorage.removeItem(item)
      localStorage.removeItem(`${item}-details`)
    })
  }

  return (
    <ReportFiltersContext.Provider
      value={{
        setFilter,
        getFilter,
        clearFilter,
        resetAllFilters,
      }}
    >
      {children}
    </ReportFiltersContext.Provider>
  )
}

export function useReportFilters(): ReportFiltersContextState {
  const context = useContext(ReportFiltersContext)

  return context
}
