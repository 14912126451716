import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { Typography, Box } from '@mui/material'

import { Transaction, api } from '@shared/api/src'
import { Loading, AccountType, SurchargeDisclosure } from '@shared/components'
import AppTheme from '@shared/design'
import { useEnforceLogin } from '@shared/hooks'
import {
  codeToEntryMode,
  codeToTransactionStatus,
  mapTransactionTypeId,
  codeToReason,
} from '@shared/mapping/gateway-transactions'
import {
  PaymentMethodType,
  GatewayTransactionEntryModeKey,
} from '@shared/types'
import { calculateSubtotal, currency, formatDatetime } from '@shared/utils'

interface PrintTransactionSummaryProps {
  transaction?: Transaction
  productTransaction?: Transaction['product_transaction']
}

const useStyles = tss
  .withName('PrintTransactionSummary')
  .create(({ theme }) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: '100vh',
      margin: '0',
    },

    transactionField: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      maxWidth: '500px',
      boxSizing: 'border-box',
    },

    divider: {
      width: '500px',
      height: '1px',
      background: theme.palette.divider,
      margin: theme.spacing(1, 0),
      color: 'inherit',
    },

    textLabel: {
      fontSize: '14px',
    },

    textValue: {
      fontSize: '14px',
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    receiptLogo: {
      maxWidth: '9.375rem',
      maxHeight: '3.125rem',
      marginRight: theme.spacing(2),
      display: 'block',
    },
  }))

const TransactionHeader: React.FC<PrintTransactionSummaryProps> = ({
  productTransaction,
}) => {
  const { classes } = useStyles(AppTheme)
  return productTransaction?.receipt_logo ||
    productTransaction?.receipt_header ? (
    <>
      <Box className={classes.transactionField}>
        {productTransaction?.receipt_logo && (
          <img
            alt="receipt logo"
            className={classes.receiptLogo}
            src={productTransaction.receipt_logo}
          />
        )}

        {productTransaction?.receipt_header && (
          <div
            style={{ paddingRight: '50%' }}
            dangerouslySetInnerHTML={{
              __html: productTransaction.receipt_header,
            }}
          />
        )}
      </Box>
    </>
  ) : (
    <></>
  )
}

const SubtotalsSection: React.FC<PrintTransactionSummaryProps> = ({
  transaction,
}) => {
  const { classes } = useStyles(AppTheme)
  const { t } = useTranslation()

  const showTip =
    transaction.tip_amount !== null && transaction.tip_amount !== undefined
  const showSalesTax = transaction.tax !== null && transaction.tax !== undefined
  const showSurcharge = transaction.surcharge !== null

  if (!(showTip || showSalesTax || showSurcharge)) {
    return null
  }

  return (
    <>
      <Box className={classes.transactionField}>
        <Typography component="span" className={classes.textLabel}>
          {t('common.amount-subtotal')}
        </Typography>
        <Typography component="span" className={classes.textValue}>
          {currency(calculateSubtotal(transaction), true)}
        </Typography>
      </Box>

      {showSurcharge && (
        <Box className={classes.transactionField}>
          <Typography className={classes.textLabel}>
            {t('common.amount-surcharge')}
          </Typography>
          <Typography className={classes.textValue}>
            {currency(
              ((transaction.surcharge.surcharge_amount as number) ?? 0) / 100
            )}
          </Typography>
        </Box>
      )}
      {showTip && (
        <Box className={classes.transactionField}>
          <Typography className={classes.textLabel}>
            {t('common.tip')}
          </Typography>
          <Typography className={classes.textValue}>
            {currency((transaction?.tip_amount ?? 0) / 100)}
          </Typography>
        </Box>
      )}
      {showSalesTax && (
        <Box className={classes.transactionField}>
          <Typography className={classes.textLabel}>
            {t('common.tax')}
          </Typography>
          <Typography className={classes.textValue}>
            {currency((transaction.tax ?? 0) / 100)}
          </Typography>
        </Box>
      )}
    </>
  )
}

const TotalsSection: React.FC<PrintTransactionSummaryProps> = ({
  transaction,
}) => {
  const { classes } = useStyles(AppTheme)
  const { t } = useTranslation()

  return (
    <>
      <Box className={classes.transactionField}>
        <Typography component="span" className={classes.textLabel}>
          {t('common.amount-transaction')}
        </Typography>
        <Typography component="span" className={classes.textValue}>
          {currency((transaction.transaction_amount ?? 0) / 100)}
        </Typography>
      </Box>
      {Boolean(transaction.auth_amount) &&
        transaction.auth_amount !== transaction.transaction_amount && (
          <Box className={classes.transactionField}>
            <Typography component="span" className={classes.textLabel}>
              {t('common.amount-auth')}
            </Typography>
            <Typography component="span" className={classes.textValue}>
              {currency((transaction.auth_amount ?? 0) / 100)}
            </Typography>
          </Box>
        )}
    </>
  )
}

const DetailsSection: React.FC<PrintTransactionSummaryProps> = ({
  transaction,
}) => {
  const { classes } = useStyles(AppTheme)
  const { t } = useTranslation()
  return (
    <>
      <Box className={classes.transactionField}>
        <Typography component="span" className={classes.textLabel}>
          {t('common.account-holder-name')}
        </Typography>
        <Typography component="span" className={classes.textValue}>
          {transaction.account_holder_name ?? ''}
        </Typography>
      </Box>

      <Box className={classes.transactionField}>
        <Typography className={classes.textLabel}>
          {t('common.payment-method')}
        </Typography>
        {(transaction.payment_method as PaymentMethodType) !==
        PaymentMethodType.CASH ? (
          <>
            <Typography className={classes.textValue} noWrap>
              <AccountType type={transaction.account_type} showImage={false} />-
              {transaction.last_four ?? '0000'}
            </Typography>
          </>
        ) : (
          <Typography className={classes.textValue} noWrap>
            {t('common.cash')}
          </Typography>
        )}
      </Box>

      <Box className={classes.transactionField}>
        <Typography className={classes.textLabel}>{t('Auth Code')}</Typography>
        <Typography className={classes.textValue}>
          {transaction.auth_code ?? ''}
        </Typography>
      </Box>

      <Box className={classes.transactionField}>
        <Typography className={classes.textLabel}>
          {t('common.transaction-type')}
        </Typography>
        <Typography className={classes.textValue}>
          {transaction.type_id
            ? mapTransactionTypeId(transaction.type_id)
            : t('common.unknown')}
        </Typography>
      </Box>

      <Box className={classes.transactionField}>
        <Typography className={classes.textLabel}>
          {t('common.transaction-status')}
        </Typography>
        <Typography className={classes.textValue}>
          <span>{codeToTransactionStatus(transaction.status_code)}</span>
        </Typography>
      </Box>

      <Box className={classes.transactionField}>
        <Typography className={classes.textLabel}>
          {t('common.reason-description')}
        </Typography>
        <Typography className={classes.textValue}>
          {transaction.reason_code_id
            ? codeToReason(transaction.reason_code_id.toString())
            : t('common.unknown')}
        </Typography>
      </Box>

      <Box className={classes.transactionField}>
        <Typography className={classes.textLabel}>
          {t('common.verbiage')}
        </Typography>
        <Typography className={classes.textValue}>
          {transaction.verbiage}
        </Typography>
      </Box>

      <Box className={classes.transactionField}>
        <Typography className={classes.textLabel}>
          {t('common.entry-mode')}
        </Typography>
        <Typography className={classes.textValue}>
          {' '}
          {transaction.entry_mode_id
            ? codeToEntryMode(
                transaction.entry_mode_id.toString() as GatewayTransactionEntryModeKey
              )
            : t('common.unknown')}
        </Typography>
      </Box>

      <Box className={classes.transactionField}>
        <Typography className={classes.textLabel}>
          {t('common.name-customer')}
        </Typography>
        <Typography className={classes.textValue}>
          {' '}
          {transaction.contact?.first_name ?? ''}{' '}
          {transaction.contact?.last_name ?? ''}
        </Typography>
      </Box>

      {transaction.product_transaction?.vt_order_num && (
        <Box className={classes.transactionField}>
          <Typography className={classes.textLabel}>
            {t('common.order-number')}
          </Typography>
          <Typography className={classes.textValue}>
            {transaction.order_number}
          </Typography>
        </Box>
      )}

      {transaction.product_transaction?.vt_clerk_number && (
        <Box className={classes.transactionField}>
          <Typography className={classes.textLabel}>
            {t('common.clerk-number')}
          </Typography>
          <Typography className={classes.textValue}>
            {transaction.clerk_number}
          </Typography>
        </Box>
      )}

      <Box className={classes.transactionField}>
        <Typography className={classes.textLabel}>
          {t('common.description')}
        </Typography>
        <Typography className={classes.textValue}>
          {transaction.description}
        </Typography>
      </Box>
    </>
  )
}

const DateSection: React.FC<PrintTransactionSummaryProps> = ({
  transaction,
}) => {
  const { classes } = useStyles(AppTheme)
  const { t } = useTranslation()
  const { user } = useEnforceLogin()

  return (
    <>
      <Box className={classes.transactionField}>
        <Typography component="span" className={classes.textLabel}>
          {`${t('common.date')} / ${t('common.time')}`}
        </Typography>
        <Typography component="span" className={classes.textValue}>
          {formatDatetime(transaction.created_ts, user.tz)}
        </Typography>
      </Box>
      <Box className={classes.transactionField}>
        <Typography component="span" className={classes.textLabel}>
          {t('common.transaction-id')}
        </Typography>
        <Typography component="span" className={classes.textValue}>
          {transaction.id}
        </Typography>
      </Box>
      {!!transaction.product_transaction?.surcharge?.compliant &&
        (transaction.surcharge?.surcharge_amount as number) > 0 && (
          <SurchargeDisclosure
            merchantAccount={transaction.product_transaction}
            containerStyle={{
              width: '100%',
              maxWidth: '500px',
            }}
          />
        )}
    </>
  )
}

const EmvDataSection: React.FC<PrintTransactionSummaryProps> = ({
  transaction,
}) => {
  const { classes } = useStyles(AppTheme)

  if (!transaction.emv_receipt_data) {
    return <></>
  }

  return (
    <>
      <Box className={classes.transactionField}>
        {Object.entries(transaction.emv_receipt_data).map(([label, value]) => (
          <>
            <Typography component="span" className={classes.textLabel}>
              {label}
            </Typography>
            <Typography component="span" className={classes.textLabel}>
              {value as string}
            </Typography>
          </>
        ))}
      </Box>
    </>
  )
}

const FooterSection: React.FC<PrintTransactionSummaryProps> = ({
  transaction,
}) => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const productTransaction = transaction?.product_transaction

  return (
    <div>
      {productTransaction?.receipt_vt_above_signature && (
        <Typography component="p" className={classes.textLabel}>
          {productTransaction.receipt_vt_above_signature}
        </Typography>
      )}

      {transaction?.signature?.signature ? (
        <img
          className={classes.receiptLogo}
          style={{ marginTop: '56px' }}
          src={`data:image/bmp;base64,${transaction.signature.signature}`}
          alt={t('common.signature')}
        />
      ) : (
        <>
          <hr
            className={classes.divider}
            style={{ marginTop: '56px', marginBottom: '4px' }}
          />
          <Typography
            component="p"
            className={`${classes.textLabel} ${classes.textValue}`}
            style={{ fontWeight: 'medium' }}
          >
            {t('common.signature')}
          </Typography>
        </>
      )}

      {productTransaction?.receipt_footer && (
        <div
          className={classes.transactionField}
          style={{ marginTop: '16px' }}
          dangerouslySetInnerHTML={{
            __html: productTransaction.receipt_footer,
          }}
        />
      )}
    </div>
  )
}

const UnboundPrintTransactionSummary: React.FC<
  PrintTransactionSummaryProps
> = ({ transaction }) => {
  const { classes } = useStyles(AppTheme)
  const { t } = useTranslation()
  return (
    <>
      <Box className={classes.root}>
        <TransactionHeader
          productTransaction={transaction.product_transaction}
        />
        <hr className={classes.divider}></hr>
        <SubtotalsSection transaction={transaction} />
        <hr className={classes.divider}></hr>
        <TotalsSection transaction={transaction} />
        <hr className={classes.divider}></hr>
        <DetailsSection transaction={transaction} />
        <hr className={classes.divider}></hr>
        <DateSection transaction={transaction} />
        <hr className={classes.divider}></hr>
        <EmvDataSection transaction={transaction} />
        <FooterSection transaction={transaction} />
      </Box>
    </>
  )
}

export const PrintTransactionSummary = () => {
  const { transactionId } = useParams()

  const [transaction, setTransaction] = useState<Transaction>()
  useEffect(() => {
    setTimeout(
      () =>
        api
          .service('transactions')
          .get(transactionId ?? '')
          .then(setTransaction)
          .catch((error) => {
            alert(error.message)
          }),
      1000
    )
  }, [])

  useEffect(() => {
    if (transaction) {
      setTimeout(() => window.print(), 1000)
    }
  }, [transaction])

  return transaction ? (
    <UnboundPrintTransactionSummary transaction={transaction} />
  ) : (
    <Loading />
  )
}
