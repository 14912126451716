import { errors, FeathersError, GeneralError } from '@feathersjs/errors'
import {
  Application as FeathersApplication,
  HookContext as FeathersHookContext,
  Params,
} from '@feathersjs/feathers'
import { FetchClient, Handler } from '@feathersjs/rest-client'
import { EventEmitter } from 'events'

import flattenObjectKeys from '../../utils/flat-object/flatObject'

export type AppHeaders = {
  'developer-id'?: string
  'access-token'?: string
  'user-id'?: string
  'user-api-key'?: string
}

export const getErrorMessage = (error: any) => {
  const { meta } = error

  if (meta?.new_password && meta.new_password[0]) {
    return meta.new_password[0]
  }

  if (meta?.message) {
    return meta.message
  }

  return error.detail || error.title || error
}

export const convertError = (error: any) => {
  if (error instanceof FeathersError) {
    return error
  }

  const statusCode = error.statusCode
    ? error.statusCode === 412
      ? 406
      : error.statusCode
    : 500
  const ErrorClass = (errors as any)[statusCode] || GeneralError

  return new ErrorClass(getErrorMessage(error), { error })
}

export interface Configuration {
  authentication: Promise<any>
  connection: ReturnType<Handler<{ [name: string]: FetchClient }>>
  headers: AppHeaders
}

export interface Page<T> {
  type: string
  list: T[]
  links: {
    type: string
    first: string
    next: string
    last: string
  }
  pagination: {
    type: string
    total_count: number
    page_count: number
    page_number: number
    page_size: number
  }
  sort: {
    type: string
    fields: any[]
  }
  columns?: string[]
}

// eslint-disable-next-line
export interface ServiceTypes {}

export type App = FeathersApplication<ServiceTypes, Configuration>

export type HookContext = FeathersHookContext<App>

export interface ApiQuery {
  page?: {
    number?: number
    size?: number
  }
  sort?: {
    [key: string]: string
  }
  [key: string]: any
}

// eslint-disable-next-line
export interface ApiServiceParams extends Params<ApiQuery> {}

export type ApiRequestOptions = {
  url: string
  method?: string
  body?: Record<string, any>
  headers?: Record<string, string>
  passthrough?: boolean
}

export class ApiService<T, D = Partial<T>, P = ApiServiceParams> {
  constructor(public target: FetchClient, public app: App) {}

  async apiRequest<T>(settings: ApiRequestOptions): Promise<T> {
    const { accessToken } = (await this.app.get('authentication')) || {}

    const options: Record<string, any> = {
      url: `${this.target.base}/${settings.url}`,
      method: settings.method || 'GET',
      headers: {
        ...(accessToken ? { 'access-token': accessToken } : {}),
        ...this.app.get('headers'),
        ...settings.headers,
      },
      body: settings.body,
      passthrough: settings.passthrough || false,
    }

    return this.target
      .request(options, {})
      .then((result: { data?: T }) => {
        // When the API returns empty response, meaning the resource has been deleted, but was successful
        if (!result) return true
        return result.data || result
      })
      .catch((error: unknown) => {
        const converted = convertError(error)
        this.app.emit('apiError', converted)
        throw converted
      })
  }

  async find(params: P & { paginate: true }): Promise<Page<T>>
  // eslint-disable-next-line
  async find(params?: P): Promise<T[]>
  // eslint-disable-next-line
  async find(params?: P | (P & { paginate: true })): Promise<T[] | Page<T>> {
    return this.target.find(params as any)
  }

  async get(id: string, params?: P): Promise<T> {
    return this.target.get(id, params as any)
  }

  async create(data: D, params?: P): Promise<T> {
    return this.target.create(data as any, params as any)
  }

  async patch(id: string, data: D, params?: P): Promise<T> {
    return this.target.patch(id, data as any, params as any)
  }

  async update(id: string, data: D, params?: P): Promise<T> {
    return this.target.update(id, data as any, params as any)
  }

  async remove(id: string, params?: P): Promise<null> {
    return this.target.remove(id, params as any)
  }

  async export(params?: ApiQuery & { getId?: string }): Promise<string> {
    const query: string[] = []

    if (params) {
      const formatted = flattenObjectKeys(params.query, '', ['page', 'filter'])
      Object.keys(formatted).forEach((key) => {
        const value =
          typeof formatted[key] === 'object'
            ? JSON.stringify(formatted[key])
            : formatted[key]
        query.push(encodeURIComponent(key) + '=' + encodeURIComponent(value))
      })
    }

    return this.apiRequest({
      url: `${params?.getId ?? ''}${query.length ? '?' + query.join('&') : ''}`,
      method: 'GET',
      passthrough: true,
    })
  }

  emit(event: string, params: any) {
    ;(this as unknown as EventEmitter).emit(event, params)
  }
}
