import React, { Fragment } from 'react'
import { Route } from 'react-router-dom'

import AddressablePage from './AddressablePage'
import { User } from '../../api/src'
import PageNotFound from '../../components/feedback/PageNotFound'
import { createUserPermissionSet } from '../../utils/permissions/permissions'
import { idRegex } from '../../utils/regex/regex'

class AddressablePageManager {
  addressablePages: AddressablePage[] = []
  userPermissions: Set<string> = new Set<string>()

  constructor(addressablePages: AddressablePage[], user: User) {
    this.addressablePages = addressablePages
    this.userPermissions = createUserPermissionSet(user)
  }

  addPage(addressablePage: AddressablePage) {
    this.addressablePages.push(addressablePage)
  }

  findPageByPath(path, pages = this.addressablePages) {
    for (const page of pages) {
      if (page.url === path) {
        return page
      }
      if (page.nestedPages.length > 0) {
        const foundNestedPage = this.findPageByPath(path, page.nestedPages)
        if (foundNestedPage) {
          return foundNestedPage
        }
      }
    }
    return null
  }

  renderAllAddressablePages() {
    const pages = this.addressablePages.reduce(
      (acc: JSX.Element[], addressablePage: AddressablePage, index) => {
        const renderedPage = addressablePage.renderRoute(this.userPermissions)
        if (renderedPage) {
          return [...acc, <Fragment key={index}>{renderedPage}</Fragment>]
        } else return acc
      },
      []
    )

    pages.push(
      <Route key="not-found-page" path="*" element={<PageNotFound />} />
    )

    return pages
  }

  generateAllNavigatorMenuItems() {
    return this.addressablePages
      .map((addressablePage) =>
        addressablePage.generateNavigatorMenuItem(this.userPermissions)
      )
      .filter((menuItem) => !!menuItem)
  }

  handleLocationChange(newPath: string) {
    // If the page contains an id, redirect to dashboard
    const pagePath = newPath.replace(idRegex, ':id')

    const currentPage = this.findPageByPath(pagePath)
    if (currentPage?.condition === false || idRegex.test(newPath)) {
      const parentPage = sessionStorage.getItem('portal')
      return `/${parentPage}`
    } else {
      return
    }
  }
}

export default AddressablePageManager
