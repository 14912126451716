import React from 'react'
import { tss } from 'tss-react/mui'

import {
  Box,
  checkboxClasses,
  FormControlLabel,
  Checkbox as MUICheckbox,
  Typography,
} from '@mui/material'

import CheckboxCheckedDisabled from './icons/CheckboxCheckedDisabled'
import CheckboxCheckedEnabled from './icons/CheckboxCheckedEnabled'
import CheckboxUnchecked from './icons/CheckboxUnchecked'

const useStyles = tss.withName('Checkbox').create(({ theme }) => ({
  checkBox: {
    [`&.${checkboxClasses.root}`]: {
      color: '#D64123',
    },
    [`&.${checkboxClasses.root}:hover`]: {
      background: 'rgba(214, 65, 35, .1)',
      color: 'rgba(214, 65, 35, .1)',
    },
    padding: '0 !important',
    marginRight: '9px !important',
    height: '48px',
    width: '48px',
    minHeight: '48px',
    minWidth: '48px',
  },
  label: {
    color: `${theme?.palette['neutral-900']} !important`,
    fontFamily: 'Inter !important',
    fontSize: '14px !important',
    fontWeight: '500 !important',
    lineHeight: '20px !important',
  },
  labelDisabled: {
    color: `${theme?.palette['neutral-500']} !important`,
  },
  labelDescription: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    color: theme.palette['neutral-500'],
  },
}))

export interface CheckboxProps {
  label: string | React.ReactNode
  description?: string | React.ReactNode
  checked: boolean
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  disabled?: boolean
  style?: React.CSSProperties
  testId?: string
  guidingId?: string
}

const Checkbox = ({
  label,
  description,
  checked,
  onChange,
  disabled = false,
  style,
  testId,
  guidingId,
}: CheckboxProps) => {
  const { classes } = useStyles()

  const getLabel = () => {
    if (typeof label === 'string' && description) {
      return labelWithDescription()
    } else if (typeof label === 'string') {
      return defaultLabel()
    } else {
      return label
    }
  }

  const defaultLabel = () => (
    <Typography
      className={`${classes.label} ${disabled ? classes.labelDisabled : ''}`}
    >
      {label}
    </Typography>
  )
  const labelWithDescription = () => (
    <div>
      <Typography
        key={0}
        className={`${classes.label} ${disabled ? classes.labelDisabled : ''}`}
      >
        {label}
      </Typography>
      <Typography
        key={1}
        className={`${classes.labelDescription} ${
          disabled ? classes.labelDisabled : ''
        }`}
      >
        {description}
      </Typography>
    </div>
  )

  return (
    <Box sx={style}>
      <FormControlLabel
        control={
          <MUICheckbox
            className={classes.checkBox}
            icon={<CheckboxUnchecked />}
            checkedIcon={
              disabled ? (
                <CheckboxCheckedDisabled />
              ) : (
                <CheckboxCheckedEnabled />
              )
            }
            inputProps={
              {
                'data-testid': testId,
              } as any // Need to cast to any because of a bug in the MUI types
            }
            checked={checked}
            disabled={disabled}
            onChange={onChange}
            data-guiding-id={guidingId}
          />
        }
        label={getLabel()}
        style={{
          marginLeft: 'auto',
          width: '100%',
        }}
        sx={{
          '& .MuiFormControlLabel-label': {
            width: '100%',
          },
        }}
      />
    </Box>
  )
}

export default Checkbox
