import { ApiService, App } from '../../commons'
import { mapResult } from '../../hooks/mapResult'
import { RecurringBillingDecline } from '../../schemas/types'

export class RecurringBillingDeclineService extends ApiService<RecurringBillingDecline> {}

declare module '../../commons' {
  interface ServiceTypes {
    'recurring-billing-declines': RecurringBillingDeclineService
  }
}

export const merchantPortalDashboardRecurringBillingDeclines = (app: App) => {
  const connection = app.get('connection')
  app.use(
    'recurring-billing-declines',
    new RecurringBillingDeclineService(
      connection.service('reporting/summary/transactions/recurring/declined'),
      app
    )
  )
  app.service('recurring-billing-declines').hooks({
    around: {
      all: [mapResult()],
    },
  })
}
