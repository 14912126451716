import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'

import FtpFeatureMfePrint from './FtpFeatureMfePrint'

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: FtpFeatureMfePrint,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    // (We will add this shortly...)
    return null
  },
})

export const { bootstrap, mount, unmount } = lifecycles
