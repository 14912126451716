import { feathers } from '@feathersjs/feathers'
import rest from '@feathersjs/rest-client'

import { authentication, Storage } from './authentication'
import axios from './axiosClient'
import { App } from './commons'
import { onError } from './hooks/onError'
import { setHeaders } from './hooks/setHeaders'
import { services } from './services/index'

export * from '@feathersjs/errors'
export * from '@feathersjs/schema'
export * from './commons'
export * from './schemas/types'
export * from './schemas/schemas'

type InitOptions = {
  root: string
  developerId: string
  storage?: Storage
}

export const init = ({ root, developerId, storage }: InitOptions) => {
  const client = rest(root)
  const app: App = feathers()

  app.set('headers', {
    'developer-id': developerId,
  })

  const connection = client.axios(axios)

  app.set('connection', connection)

  app.configure(connection)

  app.defaultService = (name: string) => {
    throw new Error(`Service ${name} not found, is it registered?`)
  }
  app.configure(authentication(storage))
  app.configure(services)
  app.hooks([setHeaders()])
  app.hooks({
    error: {
      all: [onError],
    },
  })
  return app
}

export const api = init({
  root: process.env.API_ROOT as string,
  developerId: process.env.API_DEVELOPER_ID as string,
  storage: window.localStorage,
})
