import { ApiService, App } from '../../commons'
import { expandQuery } from '../../hooks/expandQuery'
import { mapResult } from '../../hooks/mapResult'
import { Token } from '../../schemas/types'

export class TokenService extends ApiService<Token> {
  activate(tokenId: string) {
    return this.apiRequest<Token>({
      url: `${tokenId}/activate`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {},
    })
  }

  update_ach(tokenId: string, body: Token) {
    return this.apiRequest<Token>({
      url: `${tokenId}/ach`,
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    })
  }

  async update_cc(tokenId: string, body: Token) {
    return this.apiRequest<Token>({
      url: `${tokenId}/cc`,
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    })
  }

  async create_cc(body: Token) {
    return this.apiRequest<Token>({
      url: 'cc',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    })
  }

  async create_ach(body: Token) {
    return this.apiRequest<Token>({
      url: 'ach',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    })
  }
}

declare module '../../commons' {
  interface ServiceTypes {
    tokens: TokenService
  }
}

export const merchantPortalTokens = (app: App) => {
  const connection = app.get('connection')

  app.use('tokens', new TokenService(connection.service('tokens'), app), {
    methods: ['export'],
  })

  app.service('tokens').hooks({
    around: {
      all: [mapResult()],
      find: [expandQuery('created_user')],
      export: [],
    },
  })
}
