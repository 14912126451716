import { ApiService, App } from '../../commons'
import { mapResult } from '../../hooks/mapResult'
import { TokenImportMappings } from '../../schemas/types'

export class TokenImportMappingsService extends ApiService<TokenImportMappings> {}
declare module '../../commons' {
  interface ServiceTypes {
    'token-import-mappings': TokenImportMappingsService
  }
}
export const tokenImportMappings = (app: App) => {
  const connection = app.get('connection')
  app.use(
    'token-import-mappings',
    new TokenImportMappingsService(
      connection.service('merchants/token-import-mappings'),
      app
    )
  )
  app.service('token-import-mappings').hooks({
    around: {
      all: [mapResult()],
    },
  })
}
