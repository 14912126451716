import { ApiService, App } from '../commons'
import { mapResult } from '../hooks/mapResult'
import {
  SurchargeCalculation,
  SurchargeCalculationRequest,
} from '../schemas/types'

export class CalculateSurcharge extends ApiService<
  SurchargeCalculation,
  SurchargeCalculationRequest
> {}

declare module '../commons' {
  interface ServiceTypes {
    'calculate-surcharge': CalculateSurcharge
  }
}

export const calculateSurcharge = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'calculate-surcharge',
    new CalculateSurcharge(
      connection.service('public/calculate-surcharge'),
      app
    )
  )

  app.service('calculate-surcharge').hooks({
    around: {
      all: [mapResult()],
    },
  })
}
