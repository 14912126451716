import { ApiService, App } from '../../commons'
import { mapResult } from '../../hooks/mapResult'
import { paginationQuery } from '../../hooks/paginationQuery'
import { ACHSettledTransaction } from '../../schemas/types'

export class SettledTransactionsAchService extends ApiService<ACHSettledTransaction> {}

declare module '../../commons' {
  interface ServiceTypes {
    'settled-transactions-reports-ach': SettledTransactionsAchService
  }
}

export const merchantPortalSettledTransactionsAch = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'settled-transactions-reports-ach',
    new SettledTransactionsAchService(
      connection.service('reporting/reports/transactions/ach-settled'),
      app
    )
  )

  app.service('settled-transactions-reports-ach').hooks({
    around: {
      all: [paginationQuery(), mapResult()],
    },
  })
}
