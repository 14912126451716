export enum PaymentStatusCodesType {
  'UNPAID' = 'Unpaid',
  'PAID' = 'Paid',
  'PARTIALLY' = 'Partially',
}

export const paymentStatusCodes: Record<string, PaymentStatusCodesType> = {
  '1': PaymentStatusCodesType.UNPAID,
  '2': PaymentStatusCodesType.PAID,
  '3': PaymentStatusCodesType.PARTIALLY,
}

export const paymentStatusPaylinksCodes: Record<
  string,
  PaymentStatusCodesType
> = {
  '0': PaymentStatusCodesType.UNPAID,
  '1': PaymentStatusCodesType.PAID,
}
