import { useEffect, useMemo, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter, Routes } from 'react-router-dom'

import { ThemeProvider } from '@mui/material'

import packageJSON from '@/../package.json'

import AddressablePage from '@shared/addressable-page-manager/src/AddressablePage'
import AddressablePageManager from '@shared/addressable-page-manager/src/AddressablePageManager'
import { User, api } from '@shared/api/src'
import { ErrorHandler } from '@shared/components'
import AppTheme from '@shared/design'
import {
  EnforceLoginProvider,
  AuthorizationProvider,
  FtpPortalHubCommunicationProvider,
  useFtpPortalHubCommunication,
  LocationProvider,
} from '@shared/hooks'

import i18n from './i18n'
import { PrintRecurringSummary } from './pages/print/PrintRecurringSummary'
import { PrintTransactionSummary } from './pages/print/PrintTransactionSummary'

export const LOCAL_STORAGE_SESSION_TOKEN_KEY = 'token'

const MfePrintApp = () => {
  const [user, setUser] = useState<User | null>(null)
  const [addressablePageManager, setAddressablePageManager] =
    useState<AddressablePageManager>()
  const { setPortalVersion } = useFtpPortalHubCommunication()
  const pages = useMemo(() => {
    return [
      new AddressablePage(
        '',
        <PrintTransactionSummary></PrintTransactionSummary>,
        [],
        `/print/transaction/:transactionId`,
        undefined,
        true
      ),
      new AddressablePage(
        '',
        <PrintRecurringSummary></PrintRecurringSummary>,
        [],
        `/print/recurring/:recurringId`,
        undefined,
        true
      ),
    ]
  }, [])

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const fetchedUser = await getUser()
        setUser(fetchedUser)
      } catch (error) {
        console.error('ERROR GETTING/SETTING USER DATA:', error.toString())
      }
    }
    fetchUser()
    setPortalVersion({ version: packageJSON?.version })
  }, [])

  useEffect(() => {
    if (!user) return
    setAddressablePageManager(new AddressablePageManager(pages, user))
  }, [user])

  async function getUser() {
    const user = await api.service('users').authorize()
    setUser(user)
    return user
  }

  if (!addressablePageManager) return null
  return (
    <AuthorizationProvider user={user}>
      <ThemeProvider theme={AppTheme}>
        <Routes>{addressablePageManager.renderAllAddressablePages()}</Routes>
      </ThemeProvider>
    </AuthorizationProvider>
  )
}

const MfePrint: React.FC = () => {
  return (
    <FtpPortalHubCommunicationProvider>
      <I18nextProvider i18n={i18n}>
        <ErrorBoundary FallbackComponent={ErrorHandler}>
          <EnforceLoginProvider redirectToLogin>
            <BrowserRouter>
              <LocationProvider>
                <MfePrintApp />
              </LocationProvider>
            </BrowserRouter>
          </EnforceLoginProvider>
        </ErrorBoundary>
      </I18nextProvider>
    </FtpPortalHubCommunicationProvider>
  )
}

export default MfePrint
