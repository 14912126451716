import React from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import EmailIcon from '@mui/icons-material/Email'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PersonIcon from '@mui/icons-material/Person'
import PhoneIcon from '@mui/icons-material/Phone'
import { Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'

import { Contact } from '@shared/api/src'
import AppTheme from '@shared/design'
import { customerAddressToString } from '@shared/utils'

interface ContactInfoCardProps {
  contact: Contact | null
}

const useStyles = tss.withName('ContactInfoCard').create(() => ({
  listItemIcon: {
    minWidth: '30px',
  },
  listItemText: {
    marginTop: '0',
    marginLeft: '0',
  },
}))

export const ContactInfoCard: React.FC<ContactInfoCardProps> = ({
  contact,
}) => {
  const { classes } = useStyles(AppTheme)
  const { t } = useTranslation()
  const EMPTY_FIELD_PLACEHOLDER = '-'

  const getFullName = () => {
    if (!contact) return EMPTY_FIELD_PLACEHOLDER
    return `${contact.first_name ?? ''} ${contact.last_name}`
  }

  const itemData = [
    {
      label: t('common.name-customer'),
      info: getFullName(),
      Icon: PersonIcon,
    },
    {
      label: t('common.address'),
      info: !!contact?.address
        ? customerAddressToString(contact.address)
        : EMPTY_FIELD_PLACEHOLDER,
      Icon: LocationOnIcon,
    },
    {
      label: t('common.email'),
      info: !!contact?.email ? contact.email : EMPTY_FIELD_PLACEHOLDER,
      Icon: EmailIcon,
    },
    {
      label: t('common.phone'),
      info: !!contact?.cell_phone
        ? contact.cell_phone
        : EMPTY_FIELD_PLACEHOLDER,
      Icon: PhoneIcon,
    },
  ]

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      bgcolor="rgb(243, 244, 246)"
      border={1}
      borderColor="grey.300"
      borderRadius={2}
      p={2}
      my={2}
    >
      <List disablePadding>
        {itemData.map(({ label, info, Icon }, index) => (
          <ListItem key={index} disableGutters>
            <ListItemIcon className={classes.listItemIcon}>
              <Icon color="error" />
            </ListItemIcon>

            <ListItemText
              className={classes.listItemText}
              primary={label}
              secondary={info}
              primaryTypographyProps={{
                variant: 'body2',
                color: 'textSecondary',
              }}
              secondaryTypographyProps={{
                variant: 'body2',
                color: 'textPrimary',
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default ContactInfoCard
