import { ApiService, ApiServiceParams, App } from '../../commons'
import { expandQuery } from '../../hooks/expandQuery'
import { filterResults } from '../../hooks/filterResults'
import { mapResult } from '../../hooks/mapResult'
import { paginationQuery } from '../../hooks/paginationQuery'
import { Location } from '../../schemas/types'

export class LocationService extends ApiService<Location> {
  async getInfo(locationId: string, params?: ApiServiceParams) {
    const query: string[] = []
    if (params?.query) {
      Object.keys(params.query).forEach((key) => {
        query.push(
          encodeURIComponent(key) +
            '=' +
            encodeURIComponent(params.query?.[key])
        )
      })
    }
    return this.apiRequest<Location>({
      url: `${locationId}/info${query.length && '?' + query.join('&')}`,
      method: 'GET',
    })
  }

  async updateAllContactEmailReceipts(locationId: string, update: boolean) {
    return this.apiRequest({
      url: `${locationId}/update-all-contact-email-receipts`,
      method: 'PUT',
      body: {
        set_all_contact_email_trx_receipt: update,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }
}

declare module '../../commons' {
  interface ServiceTypes {
    locations: LocationService
  }
}

export const locations = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'locations',
    new LocationService(connection.service('locations'), app),
    {
      methods: ['getInfo'],
    }
  )

  app.service('locations').hooks({
    around: {
      all: [paginationQuery(), mapResult(), filterResults()],
      find: [expandQuery('terminals', 'product_transactions')],
      get: [
        expandQuery(
          'terminals',
          'product_transactions',
          'quick_invoice_setting'
        ),
      ],
      getInfo: [
        expandQuery(
          'terminals',
          'product_transactions',
          'quick_invoice_setting',
          'parent'
        ),
      ],
    },
  })
}
