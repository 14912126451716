import React, { Fragment } from 'react'
import { Route } from 'react-router-dom'

import { NavigatorMenuItem, NavigatorSubMenuItem } from '../../types'
import {
  UserPermissionSet,
  checkAllPermissions,
} from '../../utils/permissions/permissions'
class AddressablePage {
  name: string
  component: React.ReactNode
  url?: string
  permissions: string[]

  drawerIcon?: JSX.Element

  nestedPages: AddressablePage[] = []

  notInNavigator: boolean = false

  condition?: boolean = true

  guidingId?: string

  isExternal: boolean = false

  constructor(
    name: string,
    component: React.ReactNode,
    permissions: string[],
    url?: string,
    drawerIcon?: JSX.Element,
    notInNavigator?: boolean,
    condition?: boolean,
    guidingId?: string,
    isExternal: boolean = false
  ) {
    this.name = name
    this.permissions = permissions
    this.component = component
    this.url = url
    this.drawerIcon = drawerIcon
    this.notInNavigator = notInNavigator ?? false
    this.condition = condition
    this.guidingId = guidingId
    this.isExternal = isExternal
  }

  addNestedPage(addressablePage: AddressablePage) {
    this.nestedPages.push(addressablePage)
    return this
  }

  renderRoute(userPermissions: UserPermissionSet): React.ReactNode {
    if (
      this.permissions.length > 0 &&
      !checkAllPermissions(userPermissions, ...this.permissions)
    ) {
      return null
    }

    if (this.nestedPages.length == 0) {
      return <Route path={this.url} element={this.component} />
    }
    return (
      <Route path={this.url}>
        <Route index element={this.component} />
        {this.nestedPages.map((addressablePage, index) => (
          <Fragment key={index}>
            {addressablePage.renderRoute(userPermissions)}
          </Fragment>
        ))}
      </Route>
    )
  }

  generateNavigatorMenuItem(
    userPermissions: UserPermissionSet
  ): NavigatorMenuItem | null {
    if (
      this.notInNavigator ||
      (this.permissions.length > 0 &&
        !checkAllPermissions(userPermissions, ...this.permissions))
    ) {
      return null
    }

    if (
      this.condition === false ||
      (this.permissions.length > 0 &&
        !checkAllPermissions(userPermissions, ...this.permissions))
    ) {
      return null
    }

    const nestedRoutes = this.nestedPages
      .map((addressablePage) =>
        addressablePage.generateNavigatorMenuItem(userPermissions)
      )
      .filter((nested) => !!nested) as NavigatorSubMenuItem[]

    if (!this.url && nestedRoutes.length == 0) {
      return null
    }

    return {
      icon: this.drawerIcon ?? <></>,
      label: this.name,
      url: this.url,
      subMenu: nestedRoutes.length > 0 ? nestedRoutes : undefined,
      isExternal: this.isExternal,
      guidingId: this.guidingId,
    }
  }
}

export default AddressablePage
