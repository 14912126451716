// TODO: change to real user when we have the users/authorize endpoint fully
//       functioning
import { Dictionary, keyBy } from 'lodash'

import { User } from '../../api/src'
import { userNone } from '../../mocks/mockUsers'

export type UserPermissionSet = Set<string>

type Resource = {
  id: number
  title: string
  priv: string
  resource_name: string
  last_used_date: string | null
  created_ts: number
  modified_ts: number
}

const isSuperUser = (userPermissions: UserPermissionSet): boolean =>
  userPermissions.has('*')

export const checkPermission = (
  userPermissions: UserPermissionSet,
  permission: string
): boolean => isSuperUser(userPermissions) || userPermissions.has(permission)

export const checkAllPermissions = (
  userPermissions: UserPermissionSet,
  ...allPermissions: string[]
): boolean => {
  if (isSuperUser(userPermissions)) return true
  const missingPermission = allPermissions.find(
    (permission: string) => !userPermissions.has(permission)
  )
  return !missingPermission
}

export const checkSomePermissions = (
  userPermissions: UserPermissionSet,
  ...somePermissions: string[]
): boolean => {
  if (isSuperUser(userPermissions)) return true
  const atLeastOnePermission = somePermissions.find((permission: string) =>
    userPermissions.has(permission)
  )
  return !!atLeastOnePermission
}

export const getUserPermissionsMap = (user: User) =>
  keyBy(user?.resources ?? {}, 'resource_name') as Dictionary<string>

const hasWalletPermission = (user: User, permission: string) =>
  Boolean(getUserPermissionsMap(user)[`v2.accountvaults.${permission}`]) ||
  Boolean(getUserPermissionsMap(user)[`*`])

export const canCreateWallet = (user: User) => hasWalletPermission(user, 'post')

export const canViewWallet = (user: User) => hasWalletPermission(user, 'get')

export const createUserPermissionSet = (user: User): UserPermissionSet => {
  const resourcesObject:
    | {
        [x: string]: {
          created_ts?: number | null | undefined
          modified_ts?: number | null | undefined
          priv?: string | null | undefined
          last_used_date?: number | null | undefined
          title: string
          id: number
          resource_name: string
        }
      }
    | undefined
    | {} = user?.resources || {}

  const allResources = Object.entries(resourcesObject).map(([_, resource]) => {
    if (typeof resource === 'object' && 'resource_name' in resource!) {
      return resource.resource_name
    }
    return null
  })

  const userPermissionSet = new Set<string>(
    allResources.filter((resource) => resource !== null) as string[]
  )
  return userPermissionSet
}

const createUserResourceForTesting = (resource_name: string): Resource => ({
  resource_name,
  id: 1,
  title: '',
  priv: '',
  last_used_date: '',
  created_ts: 0,
  modified_ts: 0,
})

export function createUserForPermissionTesting(
  permissions: string[],
  baseUser: User = userNone
): User {
  const resources = permissions.map((permission) =>
    createUserResourceForTesting(permission)
  )

  return {
    ...baseUser,
    resources: {
      title: 'resources',
      id: 123,
      resource_name: 'resources',
      ...resources.reduce(
        (record, resource, index) => ({
          ...record,
          [index]: resource,
        }),
        {}
      ),
    },
  }
}
