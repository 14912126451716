import { ApiService, App } from '../../commons'
import { expandQuery } from '../../hooks/expandQuery'
import { mapResult } from '../../hooks/mapResult'
import { paginationQuery } from '../../hooks/paginationQuery'
import { Recurrings } from '../../schemas/types'

export class RecurringBillingsService extends ApiService<Recurrings> {
  async defer(recurringID: string, deferCount: number) {
    const recurring = await this.apiRequest<Recurrings>({
      body: {
        defer_count: deferCount,
      },
      url: `${recurringID}/defer-payment`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    })

    this.emit('patched', recurring)
    return recurring
  }

  async pause(recurringID: string) {
    const recurring = await this.apiRequest<Recurrings>({
      body: {},
      url: `${recurringID}/place-on-hold`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    })
    this.emit('updated', recurring)
    return recurring
  }

  async resume(recurringID: string) {
    const recurring = await this.apiRequest<Recurrings>({
      body: {},
      url: `${recurringID}/activate`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    })

    this.emit('updated', recurring)
    return recurring
  }

  async skip(recurringID: string, skipCount: number) {
    const recurring = await this.apiRequest<Recurrings>({
      body: {
        skip_count: skipCount,
      },
      url: `${recurringID}/skip-payment`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    })

    this.emit('patched', recurring)
    return recurring
  }
}
declare module '../../commons' {
  interface ServiceTypes {
    'recurring-billings': RecurringBillingsService
  }
}

export const merchantPortaRecurringBillings = (app: App) => {
  const connection = app.get('connection')
  app.use(
    'recurring-billings',
    new RecurringBillingsService(connection.service('recurrings'), app),
    {
      methods: ['export'],
    }
  )
  const expands = expandQuery(
    'account_vault',
    'contact',
    'payment_schedule',
    'product_transaction',
    'transactions',
    'tags',
    'created_user'
  )

  app.service('recurring-billings').hooks({
    around: {
      all: [paginationQuery(), mapResult(), expands],
      export: [expands],
    },
  })
}
