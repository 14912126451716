import { ApiService, App } from '../commons'
import { mapResult } from '../hooks/mapResult'
import { UserPrefSubscribe } from '../schemas/types'

export class UserPrefSubscribeService extends ApiService<UserPrefSubscribe> {}

declare module '../commons' {
  interface ServiceTypes {
    'user-pref-subscribes': UserPrefSubscribeService
  }
}

export const userPrefSubscribes = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'user-pref-subscribes',
    new UserPrefSubscribeService(
      connection.service('users/prefs/subscribes'),
      app
    )
  )

  app.service('user-pref-subscribes').hooks({
    around: {
      all: [mapResult()],
    },
  })
}
