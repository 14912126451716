import { ApiService, ApiServiceParams, App } from '../../commons'
import { mapResult } from '../../hooks/mapResult'
import { paginationQuery } from '../../hooks/paginationQuery'
import { DepositDetails, Deposit } from '../../schemas/types'

export class DepositsReportsService extends ApiService<
  Deposit | DepositDetails[]
> {
  async get(id: string, params: ApiServiceParams): Promise<DepositDetails[]> {
    // TODO: add hook to add fixed query params
    const query: string[] = []
    if (params.query) {
      Object.keys(params.query).forEach((key) => {
        const value =
          typeof params.query?.[key] === 'object'
            ? JSON.stringify(params.query?.[key])
            : params.query?.[key]
        query.push(encodeURIComponent(key) + '=' + encodeURIComponent(value))
      })
    }
    return this.apiRequest<DepositDetails[]>({
      method: 'GET',
      url: `${id}/details${query.length && '?' + query.join('&')}`,
    })
  }
}

declare module '../../commons' {
  interface ServiceTypes {
    'deposits-reports': DepositsReportsService
  }
}

export const merchantPortalDepositsReports = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'deposits-reports',
    new DepositsReportsService(
      connection.service('reporting/reports/deposits'),
      app
    )
  )
  app.service('deposits-reports').hooks({
    around: {
      all: [paginationQuery(), mapResult()],
    },
  })
}
