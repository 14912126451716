import { ApiService, App } from '../../commons'
import { mapResult } from '../../hooks/mapResult'
import { paginationQuery } from '../../hooks/paginationQuery'
import { RoutingNumber } from '../../schemas/types'

export class RoutingNumberService extends ApiService<RoutingNumber> {}

declare module '../../commons' {
  interface ServiceTypes {
    'routing-numbers': RoutingNumberService
  }
}

export const routingNumbers = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'routing-numbers',
    new RoutingNumberService(connection.service('routing-numbers'), app)
  )

  app.service('routing-numbers').hooks({
    around: {
      all: [paginationQuery(), mapResult()],
    },
  })
}
