import React from 'react'
import { tss } from 'tss-react/mui'

import { Box, Grid } from '@mui/material'

const useStyles = tss.withName('PageLayoutContainer').create(({ theme }) => ({
  container: {
    padding: '24px',
    display: 'flex',
    backgroundColor: 'white',
    borderRadius: '6px',
  },
  layoutWithBottomButtonBar: {
    paddingBottom: '80px',
  },
  layoutWithoutBottomButtonBar: {
    paddingBottom: '24px',
  },
}))

export interface PageLayoutContainerProps {
  testID?: string
  testIDSpacingContainer?: string
  isButtonBarAtBottom?: boolean
  useSpacing?: number
  className?: string
}

export const PageLayoutContainer: React.FC<PageLayoutContainerProps> = ({
  children,
  testID = 'page-layout-container',
  testIDSpacingContainer = 'page-layout-container-grid-spacing',
  isButtonBarAtBottom = false,
  useSpacing = 2,
  className,
}) => {
  const { classes, cx } = useStyles()

  return (
    <Box
      className={
        isButtonBarAtBottom
          ? classes.layoutWithBottomButtonBar
          : classes.layoutWithoutBottomButtonBar
      }
      data-testid={testID}
    >
      <Box className={cx(classes.container, className)}>
        <Grid
          container
          spacing={useSpacing}
          data-testid={testIDSpacingContainer}
        >
          {children}
        </Grid>
      </Box>
    </Box>
  )
}
