import axios from 'axios'

axios.interceptors.request.use((config) => {
  config.headers['access-token'] = localStorage.getItem('token')
  return config
})

axios.interceptors.response.use((response) => {
  return response
})

export default axios
