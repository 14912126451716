import { ApiService, App } from '../../commons'
import { mapResult } from '../../hooks/mapResult'
import { paginationQuery } from '../../hooks/paginationQuery'
import { Contact } from '../../schemas/types'

export class ContactSearchesService extends ApiService<Contact> {}

declare module '../../commons' {
  interface ServiceTypes {
    'contact-searches': ContactSearchesService
  }
}

export const merchantPortalContactSearches = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'contact-searches',
    new ContactSearchesService(connection.service('contact-searches'), app)
  )

  app.service('contact-searches').hooks({
    around: {
      all: [paginationQuery(), mapResult()],
    },
  })
}
