import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Box, Typography } from '@mui/material'

type UserNotAllowedProps = {
  fullHeight?: boolean
}

type StyleParams = Pick<UserNotAllowedProps, 'fullHeight'>

const useStyles = tss
  .withName('UserNotAllowed')
  .withParams<StyleParams>()
  .create(({ fullHeight }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: !!fullHeight ? '100vh' : 'unset',
    },
  }))

const UserNotAllowed: FC<UserNotAllowedProps> = ({ fullHeight = true }) => {
  const { t } = useTranslation()
  const { classes } = useStyles({ fullHeight })

  return (
    <Box className={classes.container}>
      <Typography variant="h4" component="h1" gutterBottom>
        {t('common.access-denied')}
      </Typography>
      <Typography variant="body1">
        {t('common.sorry-you-are-not-allowed-to-view-this-content')}
      </Typography>
    </Box>
  )
}

export default UserNotAllowed
