export enum TokenImportType {
  'NEW' = 'new',
  'PROCESSING' = 'processing',
  'PROCESSED' = 'processed',
  'REVERTED' = 'reverted',
}

export const tokenImportCodes: Record<string, TokenImportType> = {
  new: TokenImportType.NEW,
  processing: TokenImportType.PROCESSING,
  processed: TokenImportType.PROCESSED,
  reverted: TokenImportType.REVERTED,
}
