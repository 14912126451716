import { ApiService, App } from '../commons'
import { mapResult } from '../hooks/mapResult'
import { UserPref } from '../schemas/types'

export class UserPrefsService extends ApiService<UserPref> {}

declare module '../commons' {
  interface ServiceTypes {
    'user-prefs': UserPrefsService
  }
}

export const userPrefs = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'user-prefs',
    new UserPrefsService(connection.service('users/prefs'), app)
  )

  app.service('user-prefs').hooks({
    around: {
      all: [mapResult()],
    },
  })
}
