export enum Portal {
  PARTNER = 'partner',
  MERCHANT = 'merchant',
  PRINT = 'print',
  SERVICE = 'service',
}

const mapPortalToModule = (portal: Portal) => {
  // TODO: The Portal.SERVICE is still left to be added here,
  // which is its Module number?
  switch (portal) {
    case Portal.MERCHANT:
    case Portal.PRINT:
      return 1
    case Portal.PARTNER:
      return 2
    default:
      return 0
  }
}

export default mapPortalToModule
