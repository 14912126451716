import { valueToNumber } from '../valueToNumber'

/**
 * Currency strings can be in various formats.  This method will do its best to translate them to a number that can be used by the API
 */
export const preprocessCurrencyAmount = (
  amount: string | number | undefined | null
): number | undefined => {
  if (amount === undefined || amount === null || amount === '') {
    return undefined
  }

  // Need the round function due to JS floating-point representation
  return Math.round(valueToNumber(amount) * 100)
}
