import { ApiService, App } from '../commons'
import { mapResult } from '../hooks/mapResult'
import { ApplicationsSubmitted } from '../schemas/types'

export class ApplicationsSubmittedByMonth extends ApiService<ApplicationsSubmitted> {}

declare module '../commons' {
  interface ServiceTypes {
    'applications-submitted-by-month': ApplicationsSubmittedByMonth
  }
}

export const applicationsSubmittedByMonth = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'applications-submitted-by-month',
    new ApplicationsSubmittedByMonth(
      connection.service('partner/dashboard/submitted-apps'),
      app
    )
  )

  app.service('applications-submitted-by-month').hooks({
    around: {
      all: [mapResult()],
    },
  })
}
