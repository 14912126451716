import { debounce } from 'lodash'
import {
  ChangeEvent,
  Ref,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { tss } from 'tss-react/mui'

import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteProps,
  Box,
  TextField,
  Typography,
} from '@mui/material'

import { Page } from '@shared/api'

import { genericForwardRef } from '../../../utils/generic-forward-ref/genericForwardRef'
import { useInputStyles } from '../inputs/Input'

type StylesParams = {
  isItemSelected: boolean
  disabled?: boolean
}

const useStyles = tss
  .withName('Typeahead')
  .withParams<StylesParams>()
  .create(({ theme, isItemSelected, disabled }) => ({
    root: {
      opacity: disabled ? '.5' : undefined,
    },
    labelContainer: {
      display: 'flex',
      height: '20px',
      marginBottom: '.6em',
    },
    inputRoot: {
      backgroundColor: 'white',
      height: '44px',
    },
    input: {
      backgroundColor: isItemSelected ? 'rgb(240 249 255 / 1)' : undefined,
      color: isItemSelected ? 'rgb(7 89 133 / 1)' : undefined,
      padding: 0,
    },
    required: {
      color: theme.palette['negative'],
      marginLeft: '.3em',
    },
  }))

export type GetItemsOptions = { search?: string }

export interface TypeaheadProps<T extends unknown>
  extends Omit<
    AutocompleteProps<T, false, false, false, React.ElementType<any>>,
    | 'options'
    | 'getItemLabel'
    | 'filterOptions'
    | 'renderInput'
    | 'onChange'
    | 'isOptionEqualToValue'
  > {
  /**
   * The value of the selected item.
   */
  value: T | null

  /**
   * The typeahead items.
   */
  items?: T[] | null

  /**
   * Used to determine the string value for a given item.
   * It's used to fill the input (and the list box options if `renderOption` is not provided).
   */
  getItemLabel: (option: T) => string

  /**
   * The property used to match the selected `item` with the current `value`.
   */
  matchItemAndValueProperty: keyof T

  /**
   * The input label.
   */
  label?: string

  /**
   * Function used to retrieve the items when typing.
   */
  getItems?: (options?: GetItemsOptions) => Promise<T[] | Page<T>>

  /**
   * Function used to pass the updated items list.
   */
  onChangeItems?: (items: T[]) => void

  /**
   * Function used to pass the updated value.
   */

  onChangeValue?: (item: T | null) => void

  /**
   * The waited milliseconds between requests when typing.
   */
  debounceMs?: number

  /**
   * If `true`, filter through the local items list when typing, not through requests.
   *
   * Defaults to `false`.
   */
  localFiltering?: boolean

  /**
   * If `true`, retrieve the items on component mount,
   * the `getItems` method must also be specified.
   *
   * Defaults to `false`.
   */
  getItemsOnMount?: boolean

  /**
   * If `true`, displays an '*' alongside the input label.
   */
  required?: boolean

  /**
   * If `true`, applies error styles to the input.
   *
   * Degaults to `false`.
   */
  error?: boolean

  /**
   * The helper text content.
   */
  helperText?: string

  /**
   * The data-testid to be assigned to the input.
   */
  testId?: string

  /**
   * The data-testid to be assigned to the autocomplete.
   */
  autoCompleteTestId?: string
  guidingId?: string
}

export const Typeahead = genericForwardRef(
  <T extends unknown>(
    {
      label,
      placeholder,
      value = null,
      items: sourceItems = null,
      matchItemAndValueProperty,
      getItemLabel,
      getItems,
      onChangeItems,
      onChangeValue,
      noOptionsText,
      debounceMs = 100,
      loading,
      disabled,
      localFiltering,
      getItemsOnMount,
      required,
      error,
      helperText,
      testId,
      autoCompleteTestId,
      guidingId,
      ...props
    }: TypeaheadProps<T>,
    ref: Ref<unknown>
  ) => {
    const { classes } = useStyles({ isItemSelected: !!value, disabled })
    const { classes: inputClasses, cx } = useInputStyles()

    const [items, setItems] = useState<T[] | null>(sourceItems ?? null)
    const [searchValue, setSearchValue] = useState<string | null>(null)

    const [previousSearchValue, setPreviousSearchValue] = useState<
      string | null
    >(null)

    const [loadingItems, setLoadingItems] = useState(false)

    const isLoading = useMemo(
      () =>
        loading ||
        (!!getItemsOnMount && !items) ||
        loadingItems ||
        // Show loading when having imparity with the sourceItems,
        // "can't have more than the source".
        (items?.length ?? 0) > (sourceItems?.length ?? 0),
      [loading, loadingItems, items, sourceItems, getItemsOnMount]
    )

    const isItemEqualToValue = useCallback(
      (option: T, value: T) =>
        option[matchItemAndValueProperty] === value[matchItemAndValueProperty],
      [matchItemAndValueProperty]
    )

    const filterLocalItems = useCallback(
      (filter: string) => {
        if (!filter) return setItems(sourceItems)

        setItems(
          sourceItems?.filter((item) =>
            getItemLabel(item).toLowerCase().includes(filter.toLowerCase())
          ) ?? []
        )
      },
      [sourceItems, getItemLabel]
    )

    const isPageResult = useCallback(
      (items: Page<T> | T[]): items is Page<T> =>
        !Array.isArray(items) && (items as Page<T>).list !== undefined,
      []
    )

    const debouncedGetItems = useMemo(
      () =>
        getItems
          ? debounce(async (options?: GetItemsOptions) => {
              setItems([])
              setLoadingItems(true)

              try {
                const data = await getItems(options)
                const items = isPageResult(data) ? data.list : data

                setItems(items)
                onChangeItems?.(items)
              } finally {
                setLoadingItems(false)
              }
            }, debounceMs)
          : null,
      [getItems, onChangeItems, isPageResult, debounceMs]
    )

    const handleItemChange = useCallback(
      (
        _: SyntheticEvent<Element, Event>,
        selectedItem: T | null,
        reason: AutocompleteChangeReason,
        __?: AutocompleteChangeDetails<T>
      ) => {
        setSearchValue(null)
        onChangeValue?.(selectedItem)

        if (reason === 'clear') {
          if (localFiltering && items !== sourceItems) {
            setItems(sourceItems)
            return
          }

          // When the user clears the selected value
          // we only want to request the default values
          // if he has made a search before, because otherwise
          // the default values are already being shown
          if (previousSearchValue) {
            setPreviousSearchValue(null)
            debouncedGetItems?.()
          }
        }
      },
      [
        onChangeValue,
        localFiltering,
        items,
        sourceItems,
        previousSearchValue,
        debouncedGetItems,
      ]
    )

    const onSearchChange = useCallback(
      async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let searchValue

        if (!!value) {
          searchValue = (event.nativeEvent as InputEvent).data ?? null
          onChangeValue?.(null)
        } else {
          searchValue = event.target.value
        }

        setSearchValue(searchValue)
        setPreviousSearchValue(searchValue)

        if (localFiltering) return filterLocalItems(searchValue ?? '')
        debouncedGetItems?.({ search: searchValue ?? '' })
      },
      [
        value,
        onChangeValue,
        localFiltering,
        filterLocalItems,
        debouncedGetItems,
      ]
    )

    const customNoOptionsText = useMemo(() => {
      if (
        !searchValue ||
        (!!searchValue && localFiltering && !sourceItems?.length)
      ) {
        return noOptionsText
      }

      return 'No results found.'
    }, [searchValue, localFiltering, sourceItems, noOptionsText])

    useEffect(() => {
      if (!disabled && getItemsOnMount && getItems) {
        debouncedGetItems?.({ search: searchValue ?? '' })
      }
    }, [disabled])

    useEffect(() => {
      setItems(sourceItems)
    }, [sourceItems])

    return (
      <Box className={classes.root} ref={ref}>
        <Box className={classes.labelContainer}>
          <Typography variant="subtitle2">{label}</Typography>

          {!!required && (
            <Typography className={classes.required} variant="subtitle2">
              *
            </Typography>
          )}
        </Box>

        <Autocomplete<T>
          {...props}
          data-guiding-id={guidingId}
          data-testid={autoCompleteTestId}
          value={value ?? null}
          options={items ?? []}
          onChange={handleItemChange}
          filterOptions={(x) => x} // See https://mui.com/material-ui/react-autocomplete/#search-as-you-type
          getOptionLabel={getItemLabel}
          isOptionEqualToValue={isItemEqualToValue}
          noOptionsText={customNoOptionsText}
          renderInput={(props) => (
            <TextField
              {...props}
              placeholder={placeholder}
              onChange={onSearchChange}
              InputProps={{
                ...props.InputProps,
                className: classes.inputRoot,
              }}
              inputProps={{
                ...props.inputProps,
                className: classes.input,
                // We want to prioritize to show the User Search Value,
                // otherwise prioritize the value that comes from the Input API,
                // which is basically the one returned by `getOptionLabel`.

                // This allows that even if the user has a value already selected
                // he can start to write a search and that will be inmmediately
                // shown instead.
                value: searchValue ?? props.inputProps.value,
                'data-testid': testId,
              }}
              error={error}
              helperText={helperText}
              FormHelperTextProps={{
                className: cx(
                  inputClasses.helperText,
                  inputClasses.helperTextError
                ),
              }}
            />
          )}
          loading={isLoading}
          disabled={!!disabled}
        />
      </Box>
    )
  }
)
