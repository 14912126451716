import { ApiService, App } from '../../commons'
import { expandQuery } from '../../hooks/expandQuery'
import { mapResult } from '../../hooks/mapResult'
import { paginationQuery } from '../../hooks/paginationQuery'
import { Paylinks } from '../../schemas/types'

export class PaylinksService extends ApiService<Paylinks> {}

declare module '../../commons' {
  interface ServiceTypes {
    paylinks: PaylinksService
  }
}

export const paylinks = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'paylinks',
    new PaylinksService(connection.service('paylinks'), app),
    {
      methods: ['export'],
    }
  )

  const expands = expandQuery('contact')

  app.service('paylinks').hooks({
    around: {
      all: [paginationQuery(), mapResult(), expands],
      export: [expands],
    },
  })
}
