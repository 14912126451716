import { NextFunction } from '@feathersjs/feathers'

import { HookContext } from '../commons'

export const mapResult =
  () => async (context: HookContext, next: NextFunction) => {
    await next()
    if (context?.result?.list) {
      context.result =
        context.params.paginate === true ? context.result : context.result.list
    } else if (context?.result?.data) {
      context.result = context.result.data
    } else if (context.result?.response) {
      context.result = context.result.response
    }
  }
