import { ApiService, App } from '../commons'
import { mapResult } from '../hooks/mapResult'
import { Commission } from '../schemas/types'

export class ComissionsService extends ApiService<Commission> {
  async download(documentId: string) {
    return this.apiRequest<{ url: string }>({
      url: `${documentId}/download`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }
}

declare module '../commons' {
  interface ServiceTypes {
    commissions: ComissionsService
  }
}

export const commissions = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'commissions',
    new ComissionsService(connection.service('partner/commissions'), app)
  )

  app.service('commissions').hooks({
    around: {
      all: [mapResult()],
    },
  })
}
