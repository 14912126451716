import { tss } from 'tss-react/mui'

import {
  Step,
  Stepper as MUIStepper,
  StepLabel,
  Orientation,
} from '@mui/material'

const useStyles = tss.withName('Stepper').create(({ theme }) => ({
  stepper: {
    '& .MuiStepIcon-root': {
      color: theme.palette['neutral-200'],
      '& .MuiStepIcon-text': {
        fill: theme.palette['neutral-400'],
      },
      '&.Mui-completed': {
        color: theme.palette['balance-blue'],
        '& .MuiStepIcon-text': {
          fill: '#FFF',
        },
      },
      '&.Mui-active': {
        color: theme.palette['network-blue'],
        '& .MuiStepIcon-text': {
          fill: '#FFF',
        },
      },
    },
    '& .MuiStepConnector-root': {
      '& .MuiStepConnector-line': {
        height: '40px',
        borderWidth: '2px',
        borderColor: theme.palette['balance-blue'],
      },
    },
    '& .Mui-disabled': {
      '& .MuiStepConnector-line': {
        height: '40px',
        borderWidth: '2px',
        borderColor: theme.palette['neutral-200'],
      },
    },
  },
  label: {
    '& .MuiStepLabel-label': {
      color: theme.palette['neutral-400'],
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: '400',
      '&.Mui-completed': {
        color: theme.palette['balance-blue'],
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: '400',
      },
      '&.Mui-active': {
        color: theme.palette['network-blue'],
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: '500',
      },
    },
  },
}))

export type Step = { label: string; number: number }

export type StepperProps = {
  steps: Step[]
  activeStep: number
  orientation?: Orientation
  testId?: string
  onChange: (step: number) => void
}

function Stepper({
  steps,
  activeStep,
  orientation = 'vertical',
  testId,
  onChange,
}: StepperProps) {
  const { classes } = useStyles()

  return (
    <MUIStepper
      activeStep={activeStep}
      orientation={orientation}
      data-testid={testId}
      className={classes.stepper}
    >
      {steps.map((step) => (
        <Step key={step.label} onClick={() => onChange(step.number)}>
          <StepLabel className={classes.label}>{step.label}</StepLabel>
        </Step>
      ))}
    </MUIStepper>
  )
}
export default Stepper
