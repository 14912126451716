import React from 'react'
import { tss } from 'tss-react/mui'

import { Divider } from '@mui/material'

const useStyles = tss
  .withName('PageLayoutDivider')
  .withParams<{ isHalfMargin: boolean }>()
  .create(({ isHalfMargin }) => ({
    divider: {
      borderBottom: '1px solid #E5E7EB',
      width: '100%',
      marginTop: isHalfMargin ? '12px' : '24px',
      marginBottom: isHalfMargin ? '12px' : '24px',
    },
  }))

export interface PageLayoutDividerProps {
  testID?: string
  isHalfMargin?: boolean
}

export const PageLayoutDivider: React.FC<PageLayoutDividerProps> = ({
  testID = 'page-layout-divider',
  isHalfMargin = false,
}) => {
  const { classes } = useStyles({ isHalfMargin })

  return <Divider className={classes.divider} data-testid={testID} />
}
