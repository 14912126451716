import { Theme, createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface Palette {
    'neutral-50': string
    'neutral-100': string
    'neutral-200': string
    'neutral-300': string
    'neutral-400': string
    'neutral-500': string
    'neutral-600': string
    'neutral-700': string
    'neutral-800': string
    'neutral-900': string
    'network-blue': string
    'power-orange': string
    'level-gray': string
    'stacked-blue': string
    'stacked-blue-85': string
    'stacked-blue-70': string
    'stacked-blue-30': string
    'stacked-blue-10': string
    'energy-orange': string
    'energy-orange-85': string
    'energy-orange-70': string
    'energy-orange-30': string
    'secure-gray': string
    'secure-gray-75': string
    'secure-gray-50': string
    'secure-gray-25': string
    'charge-yellow': string
    'charge-yellow-75': string
    'charge-yellow-60': string
    'charge-yellow-30': string
    'commerce-green': string
    'commerce-green-85': string
    'commerce-green-65': string
    'commerce-green-20': string
    'balance-blue': string
    'balance-blue-85': string
    'balance-blue-60': string
    'balance-blue-20': string
    'primary-color-700': string
    'teal-50': string
    'teal-800': string
    'light-red': string
    'dark-red': string
    positive: string
    negative: string
  }

  interface PaletteOptions {
    'neutral-50'?: string
    'neutral-100'?: string
    'neutral-200'?: string
    'neutral-300'?: string
    'neutral-400'?: string
    'neutral-500'?: string
    'neutral-600'?: string
    'neutral-700'?: string
    'neutral-800'?: string
    'neutral-900'?: string
    'network-blue'?: string
    'power-orange'?: string
    'level-gray'?: string
    'stacked-blue'?: string
    'stacked-blue-85'?: string
    'stacked-blue-70'?: string
    'stacked-blue-30'?: string
    'stacked-blue-10'?: string
    'energy-orange'?: string
    'energy-orange-85'?: string
    'energy-orange-70'?: string
    'energy-orange-30'?: string
    'secure-gray'?: string
    'secure-gray-75'?: string
    'secure-gray-50'?: string
    'secure-gray-25'?: string
    'charge-yellow'?: string
    'charge-yellow-75'?: string
    'charge-yellow-60'?: string
    'charge-yellow-30'?: string
    'commerce-green'?: string
    'commerce-green-85'?: string
    'commerce-green-65'?: string
    'commerce-green-20'?: string
    'balance-blue'?: string
    'balance-blue-85'?: string
    'balance-blue-60'?: string
    'balance-blue-20'?: string
    'primary-color-700'?: string
    'teal-50'?: string
    'teal-800'?: string
    'light-red'?: string
    'dark-red'?: string
    positive?: string
    negative?: string
    disabled?: string
  }
}

const appTheme: Theme = createTheme({
  typography: {
    fontFamily: 'Inter, sans-serif',
  },
  zIndex: {
    appBar: 1,
    drawer: 2,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          backgroundColor: '#F3F4F6',
          position: 'relative',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          position: 'relative',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          position: 'relative',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },
  },
  palette: {
    'neutral-50': '#F9FAFB',
    'neutral-100': '#F3F4F6',
    'neutral-200': '#E5E7EB',
    'neutral-300': '#D1D5DB',
    'neutral-400': '#9CA3AF',
    'neutral-500': '#6B7280',
    'neutral-600': '#4B5563',
    'neutral-700': '#374151',
    'neutral-800': '#1F2937',
    'neutral-900': '#111827',
    'network-blue': '#00263A',
    'power-orange': '#D64123',
    'level-gray': '#D9D8D6',
    'stacked-blue': '#003057',
    'stacked-blue-85': '#344466',
    'stacked-blue-70': '#515978',
    'stacked-blue-30': '#A3A4BC',
    'stacked-blue-10': '#F0F9FF',
    'energy-orange': '#FF6B00',
    'energy-orange-85': '#F5803C',
    'energy-orange-70': '#F7945B',
    'energy-orange-30': '#FDCEAF',
    'secure-gray': '#414042',
    'secure-gray-75': '#636466',
    'secure-gray-50': '#939598',
    'secure-gray-25': '#C7C8CA',
    'charge-yellow': '#FFCD00',
    'charge-yellow-75': '#FFD95D',
    'charge-yellow-60': '#FFE493',
    'charge-yellow-30': '#FFEDBC',
    'commerce-green': '#00945E',
    'commerce-green-85': '#489F71',
    'commerce-green-65': '#82BE9F',
    'commerce-green-20': '#D6E6DC',
    'balance-blue': '#4197CB',
    'balance-blue-85': '#62A3D2',
    'balance-blue-60': '#8FB9DD',
    'balance-blue-20': '#D6E3F1',
    'primary-color-700': '#0369A1',
    'teal-50': '#F0FDFA',
    'teal-800': '#115E59',
    'light-red': '#F9C2C2',
    'dark-red': '#D32F2F',
    positive: '#059669',
    negative: '#EF4444',
    disabled: 'rgba(0, 0, 0, 0.38)',
  },
})

export default appTheme
