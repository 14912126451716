import { ApiService, App } from '../commons'
import { mapResult } from '../hooks/mapResult'
import { paginationQuery } from '../hooks/paginationQuery'
import { AdminAuthRole } from '../schemas/types'

export class AdminAuthRolesService extends ApiService<AdminAuthRole> {}

declare module '../commons' {
  interface ServiceTypes {
    'admin-auth-roles': AdminAuthRolesService
  }
}

export const adminAuthRoles = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'admin-auth-roles',
    new AdminAuthRolesService(connection.service('admin/auth-roles'), app)
  )
  app.service('admin-auth-roles').hooks({
    around: {
      all: [paginationQuery(), mapResult()],
      find: [],
    },
  })
}
