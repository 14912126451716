import React, { useState } from 'react'
import { tss } from 'tss-react/mui'

import { ChevronRight, MoreHoriz } from '@mui/icons-material'
import { Box, Button, Popover, Typography } from '@mui/material'

const useStyles = tss.withName('ThreeDotMenu').create(({ theme }) => ({
  threeDot: {
    color: theme.palette['neutral-700'],
    width: '16px',
    height: '16px',
    marginTop: '12px',
  },
  popover: {
    width: '252px',
    padding: '8px',
    display: 'grid',
  },
  button: {
    height: '44px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    paddingLeft: '16px',
    paddingRight: '16px',
    borderRadius: '6px',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    color: theme.palette['neutral-700'],
    '&:hover': {
      backgroundColor: theme.palette['neutral-100'],
      color: theme.palette['neutral-900'],
    },
  },
  icon: {
    fontSize: '24px !important',
    color: theme.palette['neutral-500'],
  },
  morehoriz: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

export type ThreeDotMenuOption = {
  label: string
  action: () => void
  enabled: boolean
  guidingId?: string
}

export interface ThreeDotMenuProps {
  options: ThreeDotMenuOption[]
}

const ThreeDotMenu: React.FC<ThreeDotMenuProps> = ({ options }) => {
  const { classes } = useStyles()

  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  return (
    <div
      ref={(ref) => {
        if (!ref) return

        ref.onclick = (e) => {
          setAnchorEl(e.currentTarget as HTMLElement)
          setOpen(true)
          e.stopPropagation()
        }
      }}
    >
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box className={classes.popover}>
          {options.map((option, index) => {
            if (option.enabled) {
              return (
                <Button
                  key={index}
                  className={classes.button}
                  endIcon={<ChevronRight className={classes.icon} />}
                  onClick={option.action}
                  data-guiding-id={option.guidingId}
                >
                  {option.label}
                </Button>
              )
            }
          })}
        </Box>
      </Popover>

      <Box
        className={classes.morehoriz}
        textAlign={'center'}
        data-testid="three-dot-menu"
      >
        <MoreHoriz className={classes.threeDot} />
      </Box>
    </div>
  )
}

export default ThreeDotMenu
