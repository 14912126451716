import { ApiService, App } from '../../commons'
import { mapResult } from '../../hooks/mapResult'
import { paginationQuery } from '../../hooks/paginationQuery'
import { ACHRejectReport } from '../../schemas/types'

export class AchRejectsReportsService extends ApiService<ACHRejectReport> {}

declare module '../../commons' {
  interface ServiceTypes {
    'ach-rejects-reports': AchRejectsReportsService
  }
}

export const merchantPortalAchRejectsReports = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'ach-rejects-reports',
    new AchRejectsReportsService(
      connection.service('reporting/reports/ach-rejects'),
      app
    ),
    {
      methods: ['export'],
    }
  )
  app.service('ach-rejects-reports').hooks({
    around: {
      all: [paginationQuery(), mapResult()],
      find: [],
      export: [],
    },
  })
}
