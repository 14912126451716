import { ApiService, App } from '../../commons'
import { expandQuery } from '../../hooks/expandQuery'
import { filterResults } from '../../hooks/filterResults'
import { mapResult } from '../../hooks/mapResult'
import { paginationQuery } from '../../hooks/paginationQuery'
import { Location } from '../../schemas/types'

export class LocationInfoService extends ApiService<Location> {}

declare module '../../commons' {
  interface ServiceTypes {
    'location-info': LocationInfoService
  }
}

export const locationInfo = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'location-info',
    new LocationInfoService(connection.service('locations/info'), app)
  )

  app.service('location-info').hooks({
    around: {
      all: [paginationQuery(), mapResult(), filterResults()],
      find: [expandQuery('terminals', 'product_transactions')],
      get: [
        expandQuery(
          'terminals',
          'product_transactions',
          'quick_invoice_setting'
        ),
      ],
    },
  })
}
