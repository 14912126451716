import React from 'react'
import { tss } from 'tss-react/mui'

import { InfoTwoTone, ErrorTwoTone } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

const useStyles = tss.withName('NoData').create(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50vh',
  },
  infoIcon: {
    fontSize: 50,
    color: 'rgba(110,201,241)',
  },
  errorIcon: {
    fontSize: 50,
    color: 'rgba(229,62,62)',
  },
}))

interface noDataProps {
  title: string
  label: string | number
  style?: React.CSSProperties
  icon?: string
  testId?: string
}

export const NoData: React.FC<noDataProps> = ({
  title,
  label,
  style,
  icon,
  testId,
}) => {
  const { classes } = useStyles()
  const showErrorIcon = icon === 'error'

  return (
    <Box
      className={classes.container}
      sx={{
        ...style,
      }}
      data-testid={testId}
    >
      {showErrorIcon ? (
        <ErrorTwoTone className={classes.errorIcon} />
      ) : (
        <InfoTwoTone className={classes.infoIcon} />
      )}

      <Typography variant="h4" component="h1" align="center" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body1" align="center">
        {label}
      </Typography>
    </Box>
  )
}
export default NoData
