export enum UserStatusType {
  'ACTIVE' = 'Active',
  'INACTIVE' = 'Inactive',
  'PENDING_ACTIVATION' = 'Pending Activation',
}

export enum UserStatusEnum {
  'Inactive' = 0,
  'Active' = 1,
  'PendingInvitation' = 2,
}

export const userStatusCodes: Record<string, UserStatusType> = {
  1: UserStatusType.ACTIVE,
  0: UserStatusType.INACTIVE,
  2: UserStatusType.PENDING_ACTIVATION,
}

export enum UserTypeType {
  // 'CONTACT_USER' = 'contact user', //Removed because right now doesn't need
  'LOCATION_USER' = 'Location User',
  'LOCATION_ADMIN' = 'Location Admin',
  'SUPPORT_LEVEL_1' = 'Support Level 1',
  'SUPPORT_LEVEL_2' = 'Support Level 2',
  'SUPPORT_LEVEL_3' = 'Support Level 3',
  'SUPPORT_LEVEL_4' = 'Support Level 4',
  'SUPPORT_LEVEL_5' = 'Support Level 5',
  'SUPPORT_LEVEL_6' = 'Support Level 6',
  'SUPPORT_LEVEL_7' = 'Support Level 7',
  'SUPER_ADMIN' = 'Super Admin',
}

export const userTypesCodes: Record<string, UserTypeType> = {
  // 100: 'UserTypeType.CONTACT_USER', //Removed because right now doesn't need
  200: UserTypeType.LOCATION_USER,
  250: UserTypeType.LOCATION_ADMIN,
  300: UserTypeType.SUPPORT_LEVEL_1,
  400: UserTypeType.SUPPORT_LEVEL_2,
  500: UserTypeType.SUPPORT_LEVEL_3,
  600: UserTypeType.SUPPORT_LEVEL_4,
  700: UserTypeType.SUPPORT_LEVEL_5,
  800: UserTypeType.SUPPORT_LEVEL_6,
  900: UserTypeType.SUPPORT_LEVEL_7,
  1000: UserTypeType.SUPER_ADMIN,
}
