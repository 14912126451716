export enum ProcessingStatusCodeType {
  'TO_SETTLE' = 'To Settle',
  'SETTLED' = 'Settled',
  'ERROR' = 'Error',
  'REPROCESS' = 'Re-process',
  'PROCESSING' = 'Processing',
  'PROCESSED' = 'Processed',
  'REJECT' = 'Reject',
}

export const processingStatusCodes: Record<string, ProcessingStatusCodeType> = {
  '1': ProcessingStatusCodeType.TO_SETTLE,
  '2': ProcessingStatusCodeType.SETTLED,
  '3': ProcessingStatusCodeType.ERROR,
  '4': ProcessingStatusCodeType.REPROCESS,
  '5': ProcessingStatusCodeType.PROCESSING,
}
