export enum BatchStatusType {
  'TO_SETTLE' = 'To Settle',
  'SETTLED' = 'Settled',
  'ERROR' = 'Error',
  'RE-PROCESS' = 'Re-Process',
  'PROCESSING' = 'Processing',
}

export const batchStatusCodes: Record<string, BatchStatusType> = {
  '1': BatchStatusType.TO_SETTLE,
  '2': BatchStatusType.SETTLED,
  '3': BatchStatusType.ERROR,
  '4': BatchStatusType['RE-PROCESS'],
  '5': BatchStatusType.PROCESSING,
}
