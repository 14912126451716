import { ApiService, App } from '../../commons'
import { expandQuery } from '../../hooks/expandQuery'
import { filterResults } from '../../hooks/filterResults'
import { mapResult } from '../../hooks/mapResult'
import { MerchantAccount } from '../../schemas/types'

export class ProductTransactionDetailsService extends ApiService<MerchantAccount> {}

declare module '../../commons' {
  interface ServiceTypes {
    'product-transaction-details': ProductTransactionDetailsService
  }
}

export const productTransactionDetails = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'product-transaction-details',
    new ProductTransactionDetailsService(
      connection.service('product-transaction-details'),
      app
    )
  )

  app.service('product-transaction-details').hooks({
    around: {
      all: [mapResult(), expandQuery('surcharge'), filterResults()],
    },
  })
}
