export enum TransactionType {
  CC = 'cc',
  ACH = 'ach',
  'SALE' = 'Sale',
  'AUTH_ONLY' = 'Auth Only',
  'AVS_ONLY' = 'AVS Only',
  'SETTLE' = 'Settle',
  'REFUND' = 'Refund',
  'CREDIT' = 'Credit',
  'DEBIT' = 'Debit',
}

export const transactionTypes: { [key: string]: string } = {
  '20': TransactionType.SALE,
  '21': TransactionType.AVS_ONLY,
  '22': TransactionType.SETTLE,
  '30': TransactionType.REFUND,
  '40': TransactionType.CREDIT,
  '50': TransactionType.DEBIT,
}
