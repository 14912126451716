import { ApiService, ApiServiceParams, App } from '../../commons'
import { expandQuery } from '../../hooks/expandQuery'
import { filterResults } from '../../hooks/filterResults'
import { mapResult } from '../../hooks/mapResult'
import { paginationQuery } from '../../hooks/paginationQuery'
import { MerchantAccount } from '../../schemas/types'

export class MerchantAccountService extends ApiService<MerchantAccount> {
  async activate(merchantAccountID: string) {
    const merchantAccount = await this.apiRequest<MerchantAccount>({
      body: {},
      url: `${merchantAccountID}/activate`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return merchantAccount
  }
}

declare module '../../commons' {
  interface ServiceTypes {
    'merchant-accounts': MerchantAccountService
  }
}

export const merchantPortalMerchantAccounts = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'merchant-accounts',
    new MerchantAccountService(connection.service('product-transactions'), app)
  )

  app.service('merchant-accounts').hooks({
    around: {
      all: [mapResult(), filterResults()],
    },
  })
}
