import { convertError, HookContext } from '../commons'

export function onError(context: HookContext) {
  const { app, error } = context

  if (error) {
    context.error = convertError(error)

    app.emit('apiError', context.error)
  }

  return context
}
