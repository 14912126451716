import { ApiService, App } from '../../commons'
import { mapResult } from '../../hooks/mapResult'
import { ProductTransactionAutoSettle } from '../../schemas/types'

export class ProductTransactionAutoSettlesService extends ApiService<
  ProductTransactionAutoSettle[]
> {}

declare module '../../commons' {
  interface ServiceTypes {
    'product-transaction-auto-settles': ProductTransactionAutoSettlesService
  }
}

export const productTransactionAutoSettles = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'product-transaction-auto-settles',
    new ProductTransactionAutoSettlesService(
      connection.service('product-transaction-auto-settles'),
      app
    )
  )

  app.service('product-transaction-auto-settles').hooks({
    around: {
      all: [mapResult()],
    },
  })
}
