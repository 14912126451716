export enum TransactionSourceCode {
  'UNKNOWN' = '1',
  'MOBILE' = '2',
  'WEB' = '3',
  'IVR' = '4',
  'CONTACT_STATEMENT' = '5',
  'CONTACT_PAYMENT_MOBILE' = '6',
  'CONTACT_PAYMENT' = '7',
  'QUICK_INVOICE' = '8',
  'PAY_FORM' = '9',
  'HOSTED_PAYMENT_PAGE' = '10',
  'EMULATOR' = '11',
  'INTEGRATION' = '12',
  'RECURRING_BILLING' = '13',
  'DECLINED_RECURRING_EMAIL' = '15',
  'PAYLINK' = '16',
  'ELEMENTS' = '17',
  'ACH_IMPORT' = '18',
}

export const transactionSourceCodes: Record<
  Lowercase<TransactionSourceCode>,
  string
> = {
  '1': 'Unknown',
  '2': 'Mobile',
  '3': 'Web',
  '4': 'IVR',
  '5': 'Contact Statement',
  '6': 'Contact Payment Mobile',
  '7': 'Contact Payment',
  '8': 'Quick Invoice',
  '9': 'PayForm',
  '10': 'Hosted Payment Page',
  '11': 'Emulator',
  '12': 'Integration',
  '13': 'Recurring Billing',
  '15': 'Declined Recurring Email',
  '16': 'Paylink',
  '17': 'Elements',
  '18': 'ACH Import',
}
