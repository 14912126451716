import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Box, Typography } from '@mui/material'

import appTheme from '@shared/design'

const useStyles = tss.withName('PasswordRules').create(({ theme }) => ({
  ruleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '4px',
    marginBottom: '4px',
  },
  ruleLight: {
    height: '6px',
    width: '6px',
    borderRadius: '4px',
  },
  rulePositive: {
    backgroundColor: theme.palette.positive,
  },
  ruleNegative: {
    backgroundColor: theme.palette.negative,
  },
  ruleDescription: {
    color: `${theme?.palette['neutral-500']} !important`,
    fontFamily: 'Inter !important',
    fontSize: '12px !important',
    lineHeight: '16px !important',
    marginLeft: '4px !important',
  },
}))

interface PasswordRulesProps {
  password: string
}

const PasswordRules: React.FC<PasswordRulesProps> = ({ password }) => {
  const { t } = useTranslation()
  const { classes } = useStyles(appTheme)

  const hasOneLetter = /[A-Za-z]/.test(password)
  const hasOneNumber = /[[0-9]/.test(password)
  const hasSpecialCharacter = /(\W|_)/.test(password)
  const hasEnoughCharacters = /^.{8,}$/.test(password)

  return (
    <Box>
      <Box className={classes.ruleContainer}>
        <Box
          className={`${classes.ruleLight} ${
            hasOneLetter ? classes.rulePositive : classes.ruleNegative
          }`}
        ></Box>
        <Typography className={classes.ruleDescription}>
          {t('common.password-rules.at-least-one-letter')}
        </Typography>
      </Box>

      <Box className={classes.ruleContainer}>
        <Box
          className={`${classes.ruleLight} ${
            hasOneNumber ? classes.rulePositive : classes.ruleNegative
          }`}
        ></Box>
        <Typography className={classes.ruleDescription}>
          {t('common.password-rules.at-least-one-number')}
        </Typography>
      </Box>

      <Box className={classes.ruleContainer}>
        <Box
          className={`${classes.ruleLight} ${
            hasSpecialCharacter ? classes.rulePositive : classes.ruleNegative
          }`}
        ></Box>
        <Typography className={classes.ruleDescription}>
          {t('common.password-rules.at-least-one-special-character')}
        </Typography>
      </Box>

      <Box className={classes.ruleContainer}>
        <Box
          className={`${classes.ruleLight} ${
            hasEnoughCharacters ? classes.rulePositive : classes.ruleNegative
          }`}
        ></Box>
        <Typography className={classes.ruleDescription}>
          {t('common.password-rules.at-least-eight-characters')}
        </Typography>
      </Box>
    </Box>
  )
}

export default PasswordRules
