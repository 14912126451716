import { Token } from '../../../api/src'

export enum SecCodeType {
  'CCD' = 'CCD',
  'POP' = 'POP',
  'PPD' = 'PPD',
  'TEL' = 'TEL',
  'WEB' = 'WEB',
  'C21' = 'C21',
}

export const secCodes: Record<Lowercase<SecCodeType>, SecCodeType> = {
  ccd: SecCodeType.CCD,
  pop: SecCodeType.POP,
  ppd: SecCodeType.PPD,
  tel: SecCodeType.TEL,
  web: SecCodeType.WEB,
  c21: SecCodeType.C21,
}

export const secCodeToAccountType = (code: Token['ach_sec_code']) => {
  switch (code) {
    case SecCodeType.PPD:
    case SecCodeType.TEL:
    case SecCodeType.WEB:
      return 'personal'
    default:
      return 'business'
  }
}
