import { useTranslation } from 'react-i18next'

import { Box, Typography } from '@mui/material'

import { MerchantAccount } from '@shared/api'

interface SurchargeDisclosureProps {
  merchantAccount: MerchantAccount
  isCustomerWalletTransaction?: boolean
  containerStyle?: React.CSSProperties
}

const SurchargeDisclosure = ({
  merchantAccount,
  isCustomerWalletTransaction,
  containerStyle,
}: SurchargeDisclosureProps) => {
  const { t } = useTranslation()

  return (
    <Box sx={containerStyle}>
      <Typography fontSize="14px">
        {`${t('common.merchant-assesses-surcharge')} ${
          merchantAccount?.surcharge?.surcharge_rate || 0
        }% ${merchantAccount?.surcharge?.surcharge_disclosure || ''}`}
      </Typography>
      {isCustomerWalletTransaction && (
        <Typography fontSize="14px" marginTop="12px">
          {t('common.surcharge-disclosure-token')}
        </Typography>
      )}
    </Box>
  )
}

export default SurchargeDisclosure
