import { ApiService, App } from '../../commons'
import { mapResult } from '../../hooks/mapResult'
import { RecurringTransactionsForecast } from '../../schemas/types'
export class RecurringTransactionsForecastService extends ApiService<
  RecurringTransactionsForecast[]
> {}
declare module '../../commons' {
  interface ServiceTypes {
    'recurring-transactions-forecast': RecurringTransactionsForecastService
  }
}
export const merchantPortalDashboardRecurringBillingForecast = (app: App) => {
  const connection = app.get('connection')
  app.use(
    'recurring-transactions-forecast',
    new RecurringTransactionsForecastService(
      connection.service('reporting/summary/transactions/recurring/forecast'),
      app
    )
  )
  app.service('recurring-transactions-forecast').hooks({
    around: {
      all: [mapResult()],
    },
  })
}
