import { tss } from 'tss-react/mui'

import { Chip } from '@mui/material'

export interface StatusProps {
  text?: string
  backgroundColor?:
    | 'yellow'
    | 'teal'
    | 'sky'
    | 'red'
    | 'gray'
    | 'dark-green-cyan'
}

type ColorsMapped = {
  color: string
  backgroundColor: string
}

const mapColor = (
  backgroundColor: StatusProps['backgroundColor']
): ColorsMapped => {
  switch (backgroundColor) {
    case 'yellow':
      return { color: '#D97706', backgroundColor: '#FEF3C7' }
    case 'teal':
      return { color: '#2D4A60', backgroundColor: '#E0F2F1' }
    case 'sky':
      return { color: '#3182CE', backgroundColor: '#EBF8FF' }
    case 'red':
      return { color: '#E53E3E', backgroundColor: '#FED7D7' }
    case 'gray':
      return { color: '#1F2937', backgroundColor: '#E5E7EB' }
    case 'dark-green-cyan':
      return { color: '#FFFFFF', backgroundColor: '#059669' }
  }
}

const useStyles = tss
  .withName('Status')
  .withParams<ColorsMapped>()
  .create(({ color, backgroundColor }) => ({
    root: {
      borderRadius: '4px',
      fontWeight: 'medium',
      marginBottom: '4px',
      color: color,
      backgroundColor: backgroundColor,
    },
  }))

const Status = ({ text, backgroundColor }: StatusProps) => {
  const { classes } = useStyles(mapColor(backgroundColor))

  if (!text) return null
  return <Chip label={text} className={classes.root} />
}

export default Status
