import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

import { Box, CircularProgress, Typography } from '@mui/material'

import { api } from '@shared/api'
import { Checkbox } from '@shared/components'

interface TermsAndConditions {
  code: number
  verbiage: string
}

interface TermsAndConditionsProps {
  handleAcceptTermsConditions?: (value?: TermsAndConditions) => void
}

const useStyles = tss.withName('CreateNewPassword').create(() => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    alignSelf: 'stretch',
  },
  text: {
    maxHeight: '120px',
    overflowY: 'scroll',
    fontSize: '16px',
    fontWeight: '400',
    textTransform: 'uppercase',
  },
}))

const TermsAndConditions = ({
  handleAcceptTermsConditions,
}: TermsAndConditionsProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const [loading, setLoading] = useState(false)
  const [accept, setAccept] = useState(false)
  const [termsConditions, setTermsConditions] = useState(null)

  const getTermsAndConditions = async () => {
    setLoading(true)

    try {
      const result = await api
        .service('public/terms-conditions')
        .getTermsAndConditions()
      setTermsConditions(result?.data)
    } catch (error) {
      console.error(error)
      setTermsConditions(null)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getTermsAndConditions()
  }, [])

  return (
    <Box className={classes.box}>
      <Typography fontWeight="700">
        {t('common.terms-and-conditions')}
      </Typography>
      {loading ? (
        <Box textAlign="center">
          <CircularProgress data-testid="loading-icon" size={45} />
        </Box>
      ) : (
        <>
          <Box className={classes.text}>
            <Typography>{termsConditions?.verbiage}</Typography>
          </Box>
          {handleAcceptTermsConditions && (
            <Checkbox
              label={t('common.accept-terms-and-conditions')}
              checked={accept}
              onChange={() => {
                setAccept(!accept)
                handleAcceptTermsConditions(!accept ? termsConditions : null)
              }}
              style={{
                marginLeft: '24px',
              }}
              guidingId="accept-terms-and-conditions"
            />
          )}
        </>
      )}
    </Box>
  )
}

export default TermsAndConditions
