function flattenObjectKeys(
  obj: any,
  prefix: string = '',
  ignoreKeys: string[] = []
) {
  const flattenedObj: any = {}

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key]
      const newKey = prefix ? `${prefix}[${key}]` : key

      if (
        typeof value === 'object' &&
        !Array.isArray(value) &&
        ignoreKeys.includes(key)
      ) {
        Object.assign(flattenedObj, flattenObjectKeys(value, newKey))
      } else {
        flattenedObj[newKey] = value
      }
    }
  }

  return flattenedObj
}

export default flattenObjectKeys
