export enum SettledTransactionCCStatusType {
  'SETTLED' = 'Settled',
  'REJECTED' = 'Denied, rejected or not authorized',
  'DENIED' = 'Denied / Not Authorized (Force Post)',
  'CANCELLED' = 'Cancelled',
  'UNMATCHED_REVERSAL' = 'Unmatched reversal',
}

export const settledTransactionCCStatusCodes: Record<
  string,
  SettledTransactionCCStatusType
> = {
  SETTLED: SettledTransactionCCStatusType.SETTLED,
  REJECTED: SettledTransactionCCStatusType.REJECTED,
  DENIED: SettledTransactionCCStatusType.DENIED,
  CANCELLED: SettledTransactionCCStatusType.CANCELLED,
  'UNMATCHED REVERSAL': SettledTransactionCCStatusType.UNMATCHED_REVERSAL,
}
