export const createMask = (length: number, gaps: number[]) => {
  const mask = []
  for (let i = 0; i < length; i++) {
    if (gaps.includes(i)) {
      mask.push(' ')
    }
    mask.push(/\d/)
  }
  return mask
}

export const defaultMask = createMask(16, [4, 8, 12])

// Used this repo as reference: https://github.com/PawelDecowski/jquery-creditcardvalidator
export const is_valid_luhn = (number: string) => {
  let digit, k, len1, n, sum
  sum = 0
  const ref1 = number.split('').reverse()
  for (n = k = 0, len1 = ref1.length; k < len1; n = ++k) {
    digit = ref1[n]
    digit = +digit
    if (n % 2) {
      digit *= 2
      if (digit < 10) {
        sum += digit
      } else {
        sum += digit - 9
      }
    } else {
      sum += digit
    }
  }
  return sum % 10 === 0
}

const mapCountries = [
  { old: 'US', new: 'USA' },
  { old: 'CA', new: 'CAN' },
]

export const mapCountryCode = (country?: string) => {
  if (!country) return ''

  const found = mapCountries.find((c) => c.old === country)
  if (found) {
    return found.new
  }
  return country
}
