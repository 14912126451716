import authenticationClient, {
  Storage,
} from '@feathersjs/authentication-client'
import { NotAuthenticated } from '@feathersjs/errors'

import { ApiService, App } from './commons'

export { Storage }

export type AuthenticationRequest = {
  strategy?: string
  username: string
  password: string
}

const getAuthErrorMessage = (e: {
  data: { error: { statusCode: number } }
  message: string
}) => {
  switch (e.data.error.statusCode) {
    case 403:
      return 'Your password has expired! You must update it to proceed.'
    case 406:
      //No message for application updates since this is displayed in a UI popup
      return ''
    case 423:
      return 'Your account is now locked (too many login attempts). You must reset your password to unlock it.'
    case 422:
      return e.message
    default:
      return 'Either your email or password is incorrect. Please try again!'
  }
}

export class AuthenticationService {
  constructor(public app: App) {}

  async create(data: AuthenticationRequest) {
    if (data.strategy === 'token') {
      return data
    }

    try {
      const { data: token } = await this.app.service('users/login').create({
        ...data,
      })
      return { token, accessToken: token.access_token }
    } catch (e: any) {
      this.app.authentication.reset()

      throw new NotAuthenticated(getAuthErrorMessage(e), {
        statusCode: e.data.statusCode,
      })
    }
  }

  async remove(accessToken: string) {
    return { accessToken }
  }
}

export const authentication = (storage?: Storage) => (app: App) => {
  app.use(
    'users/login',
    new ApiService<any>(app.get('connection').service('users/login'), app)
  )
  app.use('authentication', new AuthenticationService(app))
  app.configure(
    authenticationClient({
      storageKey: 'token',
      header: 'access-token',
      jwtStrategy: 'token',
      scheme: '',
      ...(storage && { storage }),
    }) as (this: App, app: App) => void
  )
}

declare module './commons' {
  interface ServiceTypes {
    authentication: AuthenticationService
    'users/login': ApiService<any>
  }
}
