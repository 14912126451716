import React from 'react'
import { tss } from 'tss-react/mui'

import { CircularProgress } from '@mui/material'

const useStyles = tss.withName('Loading').create(({ theme }) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
}))

interface LoadingProps extends React.HTMLAttributes<HTMLDivElement> {
  style?: React.CSSProperties
}

const Loading: React.FC<LoadingProps> = ({ style }) => {
  const { classes } = useStyles()

  return (
    <div className={classes.root} style={style} data-testid="loading-spinner">
      <CircularProgress />
    </div>
  )
}

export default Loading
