import { tss } from 'tss-react/mui'

import { Card } from '@mui/material'

const useStyles = tss.withName('ButtonBar').create({
  main: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 12px 12px 12px',
    marginBottom: '16px',
  },
  startButtonsContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 'auto',
  },
  endButtonsContainer: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
})

export type ButtonBarProps = {
  children: React.ReactNode
  style?: React.CSSProperties
}

export type ButtonBarStartProps = {
  children: React.ReactNode
}

export type ButtonBarEndProps = {
  children: React.ReactNode
}

export function ButtonBarStart({ children }: ButtonBarStartProps) {
  const { classes } = useStyles()

  return <div className={classes.startButtonsContainer}>{children}</div>
}

export function ButtonBarEnd({ children }: ButtonBarEndProps) {
  const { classes } = useStyles()
  return <div className={classes.endButtonsContainer}>{children}</div>
}

function ButtonBar({ children, style }: ButtonBarProps) {
  const { classes } = useStyles()

  return (
    <Card className={classes.main} elevation={0} sx={style}>
      {children}
    </Card>
  )
}

export default ButtonBar
