import { ApiService, App } from '../../commons'
import { expandQuery } from '../../hooks/expandQuery'
import { paginationQuery } from '../../hooks/paginationQuery'
import { Transaction } from '../../schemas/types'

export class BatchesDetailsService extends ApiService<Transaction> {}

declare module '../../commons' {
  interface ServiceTypes {
    'batches-details': BatchesDetailsService
  }
}

export const batchesdetails = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'batches-details',
    new BatchesDetailsService(connection.service('transactions'), app)
  )
  app.service('batches-details').hooks({
    around: {
      all: [paginationQuery()],
      get: [expandQuery('tags')],
      export: [expandQuery('tags')],
    },
  })
}
