import i18n, { InitOptions, Resource } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import English from './en.json'
import French from './fr.json'
import Arrows from './arrows.json'

export const commonResources: Resource = {
  en: English,
  fr: French,
  arrows: Arrows,
}

/**
 * Create a Custom i18n Instance.
 *
 * https://www.i18next.com/overview/api#createinstance
 */
export const createi18nInstance = (options?: InitOptions) => {
  const DEFAULT_NAMESPACE = 'main'

  const i18nInstance = i18n
    // for all configuration options https://www.i18next.com/overview/configuration-options
    .createInstance({
      ...options,
      resources: {
        ...options?.resources,
        en: {
          [DEFAULT_NAMESPACE]: {
            ...commonResources.en,
            ...options?.resources?.en,
          },
        },
        fr: {
          [DEFAULT_NAMESPACE]: {
            ...commonResources.fr,
            ...options?.resources?.fr,
          },
        },
        arrows: {
          [DEFAULT_NAMESPACE]: {
            ...commonResources.arrows,
            ...options?.resources?.arrows,
          },
        },
      },
      fallbackLng: 'en',
      supportedLngs: ['en', 'fr', 'arrows'],
      defaultNS: DEFAULT_NAMESPACE,
    })
    // use browser configuration to detect user language
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next
    .use(initReactI18next)

  i18nInstance.init()

  return i18nInstance
}
