import React from 'react'
import { tss } from 'tss-react/mui'

import { Grid } from '@mui/material'

const useStyles = tss
  .withName('PageLayoutContainerSide')
  .create(({ theme }) => ({
    root: {},
  }))

export interface PageLayoutContainerSideProps {
  testID?: string
}

export const PageLayoutContainerSide: React.FC<
  PageLayoutContainerSideProps
> = ({ children, testID = 'page-layout-container-side' }) => {
  const { classes } = useStyles()

  return (
    <Grid item sm={12} lg={4} xl={3} data-testid={testID}>
      {children}
    </Grid>
  )
}
