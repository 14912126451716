export enum PaymentMethodType {
  'CC' = 'cc',
  'ACH' = 'ach',
  'CASH' = 'cash',
}

export const paymentMethods: Record<
  Lowercase<PaymentMethodType>,
  PaymentMethodType
> = {
  cc: PaymentMethodType.CC,
  ach: PaymentMethodType.ACH,
  cash: PaymentMethodType.CASH,
}

export enum PaymentMethodTypeString {
  'cc' = 'Credit Card',
  'ach' = 'Bank Account',
  'cash' = 'Cash',
}
