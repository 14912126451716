import { ApiService, App } from '../../commons'
import { mapResult } from '../../hooks/mapResult'
import { AsyncStatus } from '../../schemas/types'

export class AsyncStatusService extends ApiService<AsyncStatus> {}

declare module '../../commons' {
  interface ServiceTypes {
    'async-status': AsyncStatusService
  }
}

export const asyncStatusService = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'async-status',
    new AsyncStatusService(connection.service('async/status'), app)
  )
  app.service('async-status').hooks({
    around: {
      all: [mapResult()],
    },
  })
}
