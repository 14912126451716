export const accountTypeLabel: { [key: string]: string } = {
  amex: 'American Express',
  diners: 'Diners Club',
  disc: 'Discover',
  jcb: 'JCB',
  mc: 'MasterCard',
  visa: 'Visa',
  checking: 'Checkings',
  'personal checking': 'Personal Checkings',
  'business checking': 'Business Checkings',
  savings: 'Savings',
  'personal savings': 'Personal Savings',
  'business savings': 'Business Savings',
  debit: 'Debit',
  cash: 'Cash',
}

export type AccountTypeProps = {
  type: string
  showFlag?: boolean
  showLabel?: boolean
  style?: React.CSSProperties
}
