import { ApiService, App } from '../../commons'
import { expandQuery } from '../../hooks/expandQuery'
import { mapResult } from '../../hooks/mapResult'
import { paginationQuery } from '../../hooks/paginationQuery'
import { Contact } from '../../schemas/types'

export type ContactPost = Omit<
  Contact,
  | 'id'
  | 'last_name'
  | 'created_ts'
  | 'modified_ts'
  | 'email_trx_receipt'
  | 'header_message_type'
>

export class ContactService extends ApiService<Contact, ContactPost> {
  async activate(contactId: string) {
    const contact = await this.apiRequest<Contact>({
      body: {},
      url: `${contactId}/activate`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    this.emit('updated', contact)
    return contact
  }

  async update(contactId: string, payload: Contact) {
    return this.apiRequest<Contact>({
      body: payload,
      url: `${contactId}`,
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }
}

declare module '../../commons' {
  interface ServiceTypes {
    contacts: ContactService
  }
}

export const merchantPortalContacts = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'contacts',
    new ContactService(connection.service('contacts'), app),
    {}
  )

  app.service('contacts').hooks({
    around: {
      all: [paginationQuery(), mapResult(), expandQuery('created_user')],
    },
  })
}
