import { ApiService, App } from '../../commons'
import { mapResult } from '../../hooks/mapResult'
import { ChargebackSummary } from '../../schemas/types'

export class ChargebackService extends ApiService<ChargebackSummary> {}

declare module '../../commons' {
  interface ServiceTypes {
    chargebacks: ChargebackService
  }
}

export const merchantPortalDashboardChargebacks = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'chargebacks',
    new ChargebackService(
      connection.service('reporting/summary/transactions/chargeback'),
      app
    )
  )

  app.service('chargebacks').hooks({
    around: {
      all: [mapResult()],
    },
  })
}
