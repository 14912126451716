import {
  api,
  EftRoutingNumber,
  Location,
  Page,
  RoutingNumber,
} from '../../api/src'

type RoutingNumberServiceFn = (
  partialNumber: string
) => Promise<Page<RoutingNumber | EftRoutingNumber>>

/**
 * Returns a service dependent on location for providing and filtering routing numbers for the typeahead.
 */
export const getRoutingNumbersService =
  (location?: Location): RoutingNumberServiceFn =>
  async (partialNumber: string) => {
    const service =
      location && location.address?.country === 'CA'
        ? api.service('eft-routing-numbers')
        : api.service('routing-numbers')

    return await service.find({
      query: {
        'filter[routing_number]': `"${partialNumber}"`,
      },
      paginate: true,
    })
  }
