import { ApiService, App } from '../../commons'
import { mapResult } from '../../hooks/mapResult'
import { GatewayTransaction } from '../../schemas/types'

export class GatewayTransactionsAchService extends ApiService<GatewayTransaction> {}

declare module '../../commons' {
  interface ServiceTypes {
    'gateway-transactions-ach': GatewayTransactionsAchService
  }
}

export const merchantPortalDashboardGatewayTransactionsACH = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'gateway-transactions-ach',
    new GatewayTransactionsAchService(
      connection.service('reporting/summary/transactions/ach-gateway'),
      app
    )
  )
  app.service('gateway-transactions-ach').hooks({
    around: {
      all: [mapResult()],
    },
  })
}
