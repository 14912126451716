import { ApiService, App } from '../../commons'
import { mapResult } from '../../hooks/mapResult'
import { Terminal } from '../../schemas/types'

export class TerminalsService extends ApiService<Terminal> {}

declare module '../../commons' {
  interface ServiceTypes {
    terminals: TerminalsService
  }
}

export const Terminals = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'terminals',
    new TerminalsService(connection.service('terminals'), app)
  )

  app.service('terminals').hooks({
    around: {
      all: [mapResult()],
    },
  })
}
