import { ApiService, App } from '../commons'
import { mapResult } from '../hooks/mapResult'
import { paginationQuery } from '../hooks/paginationQuery'
import { Application as ApplicationResponse } from '../schemas/types'

export class ApplicationsService extends ApiService<ApplicationResponse> {}

declare module '../commons' {
  interface ServiceTypes {
    applications: ApplicationsService
  }
}

export const applications = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'applications',
    new ApplicationsService(connection.service('partner/applications'), app)
  )

  app.service('applications').hooks({
    around: {
      all: [paginationQuery(), mapResult()],
    },
  })
}
