import { ApiService, App } from '../../commons'
import { expandQuery } from '../../hooks/expandQuery'
import { mapResult } from '../../hooks/mapResult'
import { TokenImport } from '../../schemas/types'

export class TokenImportService extends ApiService<TokenImport> {
  async preview(token_import_id: string) {
    return this.apiRequest({
      url: `${token_import_id}/preview`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  async dryRun(token_import_id: string) {
    return this.apiRequest({
      url: `${token_import_id}/dry-run`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  async revert(token_import_id: string) {
    return this.apiRequest({
      url: `${token_import_id}/undo`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  async process(token_import_id: string) {
    return this.apiRequest({
      url: `${token_import_id}/process`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }
}
declare module '../../commons' {
  interface ServiceTypes {
    'token-imports': TokenImportService
  }
}

export const tokenImport = (app: App) => {
  const connection = app.get('connection')
  app.use(
    'token-imports',
    new TokenImportService(connection.service('merchants/token-imports'), app)
  )
  app.service('token-imports').hooks({
    around: {
      all: [mapResult()],
      get: [expandQuery('location')],
      find: [expandQuery('location')],
    },
  })
}
