import { ApiService, App } from '../commons'
import { mapResult } from '../hooks/mapResult'
import { Notification, NotificationPost } from '../schemas/types'

export class NotificationService extends ApiService<
  Notification,
  NotificationPost
> {}

declare module '../commons' {
  interface ServiceTypes {
    notifications: NotificationService
  }
}

export const notifications = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'notifications',
    new NotificationService(connection.service('notifications'), app)
  )

  // Hooks for all methods
  app.service('notifications').hooks({
    around: {
      all: [mapResult()],
    },
  })
}
