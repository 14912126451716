import { ApiService, App } from '../../commons'
import { mapResult } from '../../hooks/mapResult'
import { paginationQuery } from '../../hooks/paginationQuery'
import { CCSettledTransaction } from '../../schemas/types'

export class SettledTransactionsCcService extends ApiService<CCSettledTransaction> {}

declare module '../../commons' {
  interface ServiceTypes {
    'settled-transactions-cc': SettledTransactionsCcService
  }
}

export const merchantPortalDashboardSettledTransactionsCC = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'settled-transactions-cc',
    new SettledTransactionsCcService(
      connection.service('reporting/summary/transactions/cc-settled'),
      app
    )
  )

  app.service('settled-transactions-cc').hooks({
    around: {
      all: [paginationQuery(), mapResult()],
    },
  })
}
