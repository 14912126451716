export enum AchTransactionType {
  'DEBIT' = 'Debit',
  'CREDIT' = 'Credit',
}

export const achTransactionCode: Record<
  Lowercase<AchTransactionType>,
  AchTransactionType
> = {
  credit: AchTransactionType.CREDIT,
  debit: AchTransactionType.DEBIT,
}
