import { ApiService, App } from '../../commons'
import { filterResults } from '../../hooks/filterResults'
import { mapResult } from '../../hooks/mapResult'
import { Location } from '../../schemas/types'

export class AdminLocationService extends ApiService<Location> {}

declare module '../../commons' {
  interface ServiceTypes {
    'admin-location': AdminLocationService
  }
}

export const locationAdmin = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'admin-location',
    new AdminLocationService(connection.service('admin/locations'), app)
  )

  app.service('admin-location').hooks({
    around: {
      all: [mapResult(), filterResults()],
    },
  })
}
