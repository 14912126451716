import { ApiService, App } from '../commons'

export type TermsAndConditions = {
  data: {
    code: number
    verbiage: string
  }
}

export class TermsConditionsService extends ApiService<TermsAndConditions> {
  async getTermsAndConditions() {
    return this.get('latest')
  }
}

declare module '../commons' {
  interface ServiceTypes {
    'public/terms-conditions': TermsConditionsService
  }
}

export const termsAndConditions = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'public/terms-conditions',
    new TermsConditionsService(
      connection.service('public/terms-conditions'),
      app
    )
  )
}
