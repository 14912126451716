import { ApiService, App } from '../../commons'
import { filterResults } from '../../hooks/filterResults'
import { mapResult } from '../../hooks/mapResult'
import { paginationQuery } from '../../hooks/paginationQuery'
import { LocationInfo } from '../../schemas/types'

export class LocationSearchesService extends ApiService<LocationInfo> {}

declare module '../../commons' {
  interface ServiceTypes {
    'location-searches': LocationSearchesService
  }
}

export const locationSearches = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'location-searches',
    new LocationSearchesService(connection.service('location-searches'), app)
  )

  app.service('location-searches').hooks({
    around: {
      all: [paginationQuery(), mapResult(), filterResults()],
    },
  })
}
