export enum RecurringStatusType {
  'ACTIVE' = 'Active',
  'ENDED' = 'Ended',
  'PAUSED' = 'Paused',
}

export const recurringStatusCodes: Record<string, RecurringStatusType> = {
  active: RecurringStatusType.ACTIVE,
  ended: RecurringStatusType.ENDED,
  'on hold': RecurringStatusType.PAUSED,
}
