export const currency = (value: number, fromCents = false) => {
  //Some integer values are in cents, so this divides by 100 to make them dollars first
  if (fromCents) {
    value /= 100
  }

  if (isNaN(value)) {
    value = 0
  }

  return value?.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })
}
