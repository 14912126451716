import { tss } from 'tss-react/mui'

export type EnumeratedStatusProps = {
  value: string
  separator?: string
}

const useStyles = tss.withName('EnumeratedStatus').create(({ theme }) => ({
  spanStyle: {
    fontSize: '0.875rem',
    borderRadius: '4px',
    fontWeight: 500,
    paddingBlock: '0.1rem',
    paddingInline: '0.5rem',
    marginRight: '6px',
    wordWrap: 'break-word',
  },
  stringValueStyle: {
    whiteSpace: 'nowrap',
  },
  defaultStyle: {
    color: theme.palette['stacked-blue'],
    backgroundColor: theme.palette['stacked-blue-10'],
  },
  approved: {
    color: theme.palette['commerce-green'],
    backgroundColor: theme.palette['commerce-green-20'],
  },
  refused: {
    color: theme.palette['dark-red'],
    backgroundColor: theme.palette['light-red'],
  },
  pending: {
    color: theme.palette['charge-yellow'],
    backgroundColor: theme.palette['charge-yellow-30'],
  },
}))

export function EnumeratedStatus({
  value,
  separator = '',
}: EnumeratedStatusProps) {
  const { classes } = useStyles()

  const stringValue = String(value)

  const getStatusStyle = () => {
    switch (stringValue.toLowerCase()) {
      case 'approved':
        return classes.approved
      case 'refused':
        return classes.refused
      case 'pending':
        return classes.pending
      default:
        return classes.defaultStyle
    }
  }

  return (
    <span
      className={`${classes.spanStyle} ${getStatusStyle()}`}
      data-testid={`enumerated-status-${getStatusStyle()}`}
    >
      <span className={classes.stringValueStyle}>
        {stringValue}
        {separator}
      </span>
    </span>
  )
}

export default EnumeratedStatus
