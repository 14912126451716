//Values may have commas in strings for larger numbers, this cleans them up a bit to provide consistent numbers
export const valueToNumber = (value: string | number | null | undefined) => {
  if (!value) {
    return 0
  }

  if (typeof value === 'string') {
    value = Number(value.replace(/,/g, ''))
  }

  return isNaN(value) ? 0 : value
}
