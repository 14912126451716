import { ApiService, App } from '../commons'
import { mapResult } from '../hooks/mapResult'
import { QuickInvoiceSetting } from '../schemas/types'
export class QuickInvoiceSettingsService extends ApiService<QuickInvoiceSetting> {
  async update(id: string, payload: Partial<QuickInvoiceSetting>) {
    return this.apiRequest<QuickInvoiceSetting>({
      body: payload,
      url: `${id}`,
      method: 'PATCH',
    })
  }
}

declare module '../commons' {
  interface ServiceTypes {
    'quick-invoice-settings': QuickInvoiceSettingsService
  }
}

export const quickInvoiceSettings = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'quick-invoice-settings',
    new QuickInvoiceSettingsService(
      connection.service('quick-invoice-settings'),
      app
    )
  )

  app.service('quick-invoice-settings').hooks({
    around: {
      all: [mapResult()],
    },
  })
}
