import { ApiService, App } from '../commons'
import { mapResult } from '../hooks/mapResult'
import { MonthlyResiduals } from '../schemas/types'

export class DashboardMonthlyResiduals extends ApiService<MonthlyResiduals> {}

declare module '../commons' {
  interface ServiceTypes {
    'dashboard-monthly-residuals': DashboardMonthlyResiduals
  }
}

export const dashboardMonthlyResiduals = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'dashboard-monthly-residuals',
    new DashboardMonthlyResiduals(
      connection.service('partner/dashboard/monthly-residuals'),
      app
    )
  )

  app.service('dashboard-monthly-residuals').hooks({
    around: {
      all: [mapResult()],
    },
  })
}
