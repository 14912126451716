import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'
import * as yup from 'yup'

import { Box, Typography } from '@mui/material'

import { Transaction, api } from '@shared/api/src'
import { AccountType, Input, SelectComponent } from '@shared/components'
import { useLocations } from '@shared/hooks'
import { apiFieldNameToUpperCase, currency } from '@shared/utils'

import { ActionModal } from '../../modals/action-modal/ActionModal'

interface VoidTransactionModalProps {
  open: boolean
  transaction: Transaction
  onClose: () => void
  onSuccess?: () => void
  onError?: () => void
}

const useStyles = tss.withName('VoidTransactionModal').create(({ theme }) => ({
  modal: {
    minHeight: '400px',
    width: '640px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  subtitle: {
    fontSize: '20px',
    fontWeight: 500,
    color: theme.palette['neutral-700'],
  },
  fieldContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  fieldLabel: {
    color: theme.palette['neutral-600'],
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  fieldValue: {
    color: theme.palette['neutral-900'],
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
  },
  accountTypeFieldValue: {
    display: 'flex',
    gap: '4px',
  },
}))

const voidSchema = yup.object({
  description: yup.string().optional(),
  tags: yup.array(yup.string()).optional(),
})

type VoidTransactionData = yup.InferType<typeof voidSchema>

const VoidTransactionModal = ({
  transaction,
  open,
  onClose,
  onSuccess,
  onError,
}: VoidTransactionModalProps) => {
  const { t } = useTranslation()
  const { classes, cx } = useStyles()
  const { selectedLocation } = useLocations()

  const formMethods = useForm<VoidTransactionData>({
    resolver: yupResolver(voidSchema),
  })

  const [loading, setLoading] = useState(false)

  const tagsOptions = useMemo(() => {
    if (!selectedLocation?.tags) return []
    return selectedLocation.tags.map((tag) => ({
      label: tag.title,
      value: tag.title,
    }))
  }, [selectedLocation])

  const handleClose = () => {
    formMethods.reset()
    onClose()
  }

  const onSubmit = async (data: VoidTransactionData) => {
    setLoading(true)

    try {
      await api.service('transactions').void(transaction.id, data)
      onSuccess?.()
      handleClose()
    } catch (error) {
      // TODO: Consider moving this logic to a shared function
      if (error?.data?.error?.statusCode === 412) {
        error.data?.error.meta.details.forEach((errorDetail) => {
          const fieldPath = errorDetail.context.label
          const fieldName = errorDetail.context.key
          const message = errorDetail.message.replace(
            `"${fieldPath}"`,
            apiFieldNameToUpperCase(fieldName)
          )
          formMethods.setError(fieldName, { message })
        })
      }
      onError?.()
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!open || !transaction) return
    formMethods.setValue('description', transaction.description || undefined)
    formMethods.setValue(
      'tags',
      transaction.tags?.map((tag) => tag.title)
    )
  }, [open, transaction])

  return (
    <ActionModal
      open={open}
      title={t('common.action.transaction.void')}
      buttons={[
        {
          testId: 'cancel-button',
          label: t('common.cancel'),
          color: 'secondary',
          onClick: handleClose,
          guidingId: 'voidtransaction-cancel',
        },
        {
          testId: 'void-transaction-button',
          label: t('common.action.transaction.void'),
          color: 'primary',
          isLoading: loading,
          onClick: formMethods.handleSubmit(onSubmit),
          guidingId: 'voidtransaction-void',
        },
      ]}
      className={classes.modal}
      onClose={handleClose}
      guidingId="voidtransaction"
    >
      <Box className={classes.container}>
        <Typography className={classes.subtitle}>
          {t('common.transaction-details')}
        </Typography>

        <Box className={classes.fieldContainer}>
          <Typography className={classes.fieldLabel}>
            {`${t('common.account-type')} / ${t('common.token-last-four')}`}
          </Typography>

          <Box
            className={cx(classes.fieldValue, classes.accountTypeFieldValue)}
          >
            <AccountType type={transaction.account_type} showImage={false} />
            <Typography>-</Typography>
            <Typography>{transaction.last_four}</Typography>
          </Box>
        </Box>

        <Box className={classes.fieldContainer}>
          <Typography className={classes.fieldLabel}>
            {t('common.amount-transaction')}
          </Typography>

          <Typography className={classes.fieldValue}>
            {currency(transaction.transaction_amount, true)}
          </Typography>
        </Box>

        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <Box className={classes.container}>
              <Controller
                name="description"
                control={formMethods.control}
                render={({ field }) => (
                  <Input
                    label={t('common.description')}
                    placeholder={t('common.description')}
                    {...field}
                    error={!!formMethods.formState.errors.description}
                    helperText={
                      formMethods.formState.errors.description
                        ?.message as string
                    }
                    guidingId="voidtransaction-description"
                  />
                )}
              />

              <Controller
                name="tags"
                control={formMethods.control}
                render={({ field }) => (
                  <SelectComponent
                    multiple
                    style={{
                      width: '100%',
                      maxWidth: 'unset',
                      border: 'unset',
                      height: '44px',
                    }}
                    options={tagsOptions}
                    value={field.value}
                    label={t('common.tags')}
                    placeholder={t('common.tags-select')}
                    onChange={field.onChange}
                    guidingId="voidtransaction-tags"
                  />
                )}
              />
            </Box>
          </form>
        </FormProvider>
      </Box>
    </ActionModal>
  )
}

export default VoidTransactionModal
