import { FC } from 'react'
import { tss } from 'tss-react/mui'

import { Box, Typography } from '@mui/material'

import { Search } from '@shared/icons'

const useStyles = tss
  .withName('EmptyFilterPlaceholder')
  .create(({ theme }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      padding: '24px 10px',
      border: `1px solid ${theme.palette['neutral-300']}`,
      borderRadius: '6px',
    },
    icon: {
      width: '32px',
      height: '32px',
      color: theme.palette['neutral-700'],
    },
    iconContainer: {
      width: '64px',
      height: '64px',
      backgroundColor: theme.palette['neutral-100'],
      borderRadius: '89px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '12px',
    },
    title: {
      color: theme.palette['neutral-700'],
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '20px',
      marginBottom: '8px',
      textAlign: 'center',
    },
    label: {
      color: theme.palette['neutral-500'],
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
    },
  }))

interface EmptyFilterPlaceholderProps {
  title: string
  label?: string
  style?: React.CSSProperties
  testId?: string
}

export const EmptyFilterPlaceholder: FC<EmptyFilterPlaceholderProps> = ({
  title,
  label,
  style,
  testId,
}) => {
  const { classes } = useStyles()

  return (
    <Box
      className={classes.container}
      sx={{
        ...style,
      }}
      data-testid={testId}
    >
      <Box className={classes.iconContainer}>
        <Search className={classes.icon} />
      </Box>
      <Typography className={classes.title}>{title}</Typography>

      {label && <Typography className={classes.label}>{label}</Typography>}
    </Box>
  )
}
