import {
  MerchantAccount,
  ProductTransactionDetail,
  User,
} from '../../../api/src'
import { TransactionType } from '../../../api/src/schemas/types'
import { PaymentMethod, ProcessMethod, SelectOption } from '../../../types'
import { getUserPermissionsMap } from '../../permissions/permissions'

const SALE_OPTION: SelectOption<TransactionType> = {
  label: 'sale',
  value: 'sale',
}

const AUTH_ONLY_OPTION: SelectOption<TransactionType> = {
  label: 'auth only',
  value: 'authonly',
}

const AVS_ONLY_OPTION: SelectOption<TransactionType> = {
  label: 'avs only',
  value: 'avsonly',
}

const REFUND_OPTION: SelectOption<TransactionType> = {
  label: 'refund',
  value: 'refund',
}

const FORCE_OPTION: SelectOption<TransactionType> = {
  label: 'force',
  value: 'force',
}

const CASH_SALE_OPTION: SelectOption<TransactionType> = {
  ...SALE_OPTION,
  value: 'cash.sale',
}

const CASH_REFUND_OPTION: SelectOption<TransactionType> = {
  ...REFUND_OPTION,
  value: 'cash.refund',
}

const COLLECT_DEBIT_OPTION: SelectOption<TransactionType> = {
  label: 'collect (debit)',
  value: 'debit',
}

const SEND_CREDIT_OPTION: SelectOption<TransactionType> = {
  label: 'send (credit)',
  value: 'credit',
}

const ccTransactionTypesOptions: SelectOption<TransactionType>[] = [
  SALE_OPTION,
  AUTH_ONLY_OPTION,
  AVS_ONLY_OPTION,
  REFUND_OPTION,
  FORCE_OPTION,
]

const achTransactionTypesOptions: SelectOption<TransactionType>[] = [
  COLLECT_DEBIT_OPTION,
  SEND_CREDIT_OPTION,
]

const cashTransactionTypesOptions: SelectOption<TransactionType>[] = [
  CASH_SALE_OPTION,
  CASH_REFUND_OPTION,
]

const permissionPath = 'v2.transactions.post'

const filterAchTransactionTypes = (
  account: MerchantAccount | ProductTransactionDetail,
  transactionTypeOptions: SelectOption<TransactionType>[]
): SelectOption<TransactionType>[] => {
  return transactionTypeOptions.filter(
    (option) =>
      (option.value === COLLECT_DEBIT_OPTION.value &&
        account.ach_allow_debit) ||
      (option.value === SEND_CREDIT_OPTION.value && account.ach_allow_credit)
  )
}

const filterCcTransactionTypes = (
  account: MerchantAccount | ProductTransactionDetail,
  transactionTypeOptions: SelectOption<TransactionType>[],
  processMethod?: ProcessMethod
): SelectOption<TransactionType>[] => {
  return transactionTypeOptions.filter((option) => {
    const refundOptions: TransactionType[] = [REFUND_OPTION.value]
    return (
      (!refundOptions.includes(option.value) || !!account.allow_blind_refund) &&
      !(option.value === FORCE_OPTION.value && processMethod === 'terminal')
    )
  })
}

const filterCashTransactionTypes = (
  account: MerchantAccount | ProductTransactionDetail,
  transactionTypeOptions: SelectOption<TransactionType>[]
): SelectOption<TransactionType>[] => {
  return transactionTypeOptions.filter((option) => {
    const refundOptions: TransactionType[] = [CASH_REFUND_OPTION.value]
    return !refundOptions.includes(option.value) || !!account.allow_blind_refund
  })
}

export const getTransactionTypesForAccount = (
  account: MerchantAccount | ProductTransactionDetail | undefined,
  user: User,
  processMethod?: ProcessMethod
): SelectOption<TransactionType>[] => {
  if (!account) return []

  const transactionTypeOptions = getTransactionTypesForPaymentMethod(
    account.payment_method as PaymentMethod
  )

  let baseMethods: SelectOption<TransactionType>[]
  switch (account.payment_method) {
    case 'cc':
      baseMethods = filterCcTransactionTypes(
        account,
        transactionTypeOptions,
        processMethod
      )
      break

    case 'ach':
      baseMethods = filterAchTransactionTypes(account, transactionTypeOptions)
      break

    case 'cash':
      // available cash types only rely on logged in user privs. not any other merchantaccount properties
      baseMethods = filterCashTransactionTypes(account, transactionTypeOptions)
      break

    default:
      baseMethods = []
  }

  // Uses a map to avoid having to iterate through permissions list for each permission.
  const permissions = getUserPermissionsMap(user)

  return baseMethods.filter(
    (method) => !!permissions[`${permissionPath}.${method.value}`]
  )
}

export const getTransactionTypesForPaymentMethod = (
  paymentMethod: PaymentMethod
): SelectOption<TransactionType>[] => {
  switch (paymentMethod) {
    case 'cc':
      return ccTransactionTypesOptions

    case 'ach':
      return achTransactionTypesOptions

    case 'cash':
      return cashTransactionTypesOptions

    default:
      return []
  }
}
