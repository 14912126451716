import { Ajv, Infer, schema } from '@feathersjs/schema'
import addFormats from 'ajv-formats'

const ajv = addFormats(
  new Ajv({
    strict: false,
    logger: false,
    allErrors: true,
  }),
  ['email', 'regex']
)

export const loginSchema = schema(
  {
    $id: '/login',
    type: 'object',
    additionalProperties: false,
    required: ['email', 'password'],
    properties: {
      email: {
        type: 'string',
        format: 'email',
        minLength: 1,
      },
      password: {
        type: 'string',
        minLength: 1,
      },
    },
  } as const,
  ajv
)
export type LoginData = Infer<typeof loginSchema>

export const resetPasswordSchema = schema(
  {
    $id: '/signin/forgot-password',
    type: 'object',
    additionalProperties: false,
    required: ['email'],
    properties: {
      email: {
        type: 'string',
        format: 'email',
        minLength: 1,
      },
    },
  } as const,
  ajv
)
export type ResetPasswordData = Infer<typeof resetPasswordSchema>
