import { forwardRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Box, Typography, IconButton, FormHelperText } from '@mui/material'

import { ActionModal } from '@shared/components'
import { useNotification } from '@shared/hooks'
import { TrashCan } from '@shared/icons'

interface FileUploadProps {
  description?: string
  maxSize?: number
  acceptedTypes?: string[]
  maxFiles?: number
  onFileChange: (
    files: { file_name: string; file?: File; content?: string }[]
  ) => void
  initialFiles?: { file_name: string }[]
  disabled?: boolean
  error?: boolean
  helperText?: string
  showAddOption?: boolean
  showDeleteOption?: boolean
  guidingId?: string
  successMessage?: string
  auxHelperText?: string
}

const useStyles = tss.create(({ theme }) => ({
  labelText: {
    color: theme.palette['neutral-700'],
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    marginBottom: '8px',
  },
  uploadBox: {
    border: `2px dashed ${theme.palette['neutral-300']}`,
    padding: '40px',
    textAlign: 'center',
    cursor: 'pointer',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'border-color 0.3s ease',
    '&:hover': {
      backgroundColor: theme.palette['neutral-100'],
    },
  },
  uploadBoxDragOver: {
    border: `2px dashed ${theme.palette['stacked-blue']}`,
  },
  uploadBoxDisabled: {
    backgroundColor: theme.palette['neutral-200'],
    border: `2px dashed ${theme.palette['neutral-400']}`,
    cursor: 'not-allowed',
    '& *': {
      color: theme.palette['neutral-500'],
    },
  },
  inlineText: {
    display: 'inline',
  },
  uploadText: {
    color: theme.palette['neutral-900'],
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    marginRight: '4px',
  },
  dragDropText: {
    color: theme.palette['neutral-700'],
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
  },
  descriptionText: {
    color: theme.palette['neutral-600'],
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    marginTop: '8px',
  },
  fileList: {
    marginTop: '12px',
  },
  fileItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 0',
    borderBottom: `1px solid ${theme.palette['neutral-300']}`,
  },
  fileName: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    color: theme.palette['neutral-900'],
  },
  fileDeleteButton: {
    color: theme.palette['neutral-600'],
  },
  helperTextError: {
    marginLeft: '0 !important',
    fontFamily: 'Inter !important',
    fontSize: '12px !important',
    fontWeight: '500 !important',
    lineHeight: '16px !important',
    color: `${theme?.palette['negative']} !important`,
  },
}))

export const FileUpload = forwardRef<HTMLInputElement, FileUploadProps>(
  (
    {
      description,
      maxSize = 5 * 1024 * 1024,
      acceptedTypes,
      maxFiles = 4,
      onFileChange,
      initialFiles = [],
      disabled = false,
      error,
      helperText,
      showAddOption = true,
      showDeleteOption = true,
      guidingId,
      successMessage,
      auxHelperText,
    },
    ref
  ) => {
    const { classes } = useStyles()
    const { t } = useTranslation()
    const { setNotification } = useNotification()

    const [fileData, setFileData] = useState<
      { file: File; file_name: string; content: string }[]
    >(
      initialFiles.map((file) => ({
        file_name: file.file_name,
        content: '',
        file: null as any,
      }))
    )

    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
    const [fileToDeleteIndex, setFileToDeleteIndex] = useState<number | null>(
      null
    )
    const [isDragOver, setIsDragOver] = useState(false)

    const convertFileToBase64 = (file: File): Promise<string | null> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result as string)
        reader.onerror = (error) => reject(error)
        reader.readAsDataURL(file)
      })
    }

    const handleFileChange = async (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const selectedFiles = event.target.files
      if (selectedFiles && selectedFiles.length > 0) {
        await processFiles(selectedFiles)
      }
    }

    const processFiles = async (selectedFiles: FileList) => {
      const newFileData = []
      let invalidFileDetected = false

      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i]

        if (file.size > maxSize) {
          setNotification({
            type: 'error',
            label: t('common.file-size-exceeded', {
              maxSize: `${maxSize / (1024 * 1024)} MB`,
            }),
          })
          invalidFileDetected = true
          continue
        }
        if (acceptedTypes && !acceptedTypes.includes(file.type)) {
          setNotification({
            type: 'error',
            label: t('common.invalid-file-format'),
          })
          invalidFileDetected = true
          continue
        }

        const base64File = await convertFileToBase64(file)

        const fileData = {
          file_name: file.name,
          content: base64File.split(',')[1],
        }

        newFileData.push(fileData)
      }

      if (invalidFileDetected) {
        return
      }

      if (newFileData.length > 0) {
        const updatedFileData = [...fileData, ...newFileData].slice(0, maxFiles)
        setFileData(updatedFileData)
        onFileChange(
          updatedFileData.map((data) => ({
            file_name: data.file_name,
            content: data.content,
          }))
        )

        setNotification({
          type: 'success',
          label: successMessage,
        })
      }
    }

    const handleDeleteFile = () => {
      if (fileToDeleteIndex !== null) {
        const updatedFileData = fileData.filter(
          (_, index) => index !== fileToDeleteIndex
        )
        setFileData(updatedFileData)
        setDeleteModalOpen(false)
        setFileToDeleteIndex(null)
        onFileChange(
          updatedFileData.map(({ file_name, file, content }) => ({
            file_name,
            file,
            content,
          }))
        )

        setNotification({
          type: 'success',
          label: t('common.file-was-deleted-successfully'),
        })
      }
    }

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      setIsDragOver(true)
    }

    const handleDragLeave = () => {
      setIsDragOver(false)
    }

    const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      setIsDragOver(false)
      const selectedFiles = event.dataTransfer.files
      if (selectedFiles && selectedFiles.length > 0) {
        await processFiles(selectedFiles)
      }
    }

    const isDisabled = disabled || fileData.length >= maxFiles

    return (
      <Box>
        <Typography className={classes.labelText}>
          {t('common.file')}
        </Typography>
        {showAddOption ? (
          <>
            <Box
              className={`${classes.uploadBox} ${
                isDisabled
                  ? classes.uploadBoxDisabled
                  : isDragOver
                  ? classes.uploadBoxDragOver
                  : ''
              }`}
              onClick={() =>
                !isDisabled &&
                document.getElementById('file-upload-input')?.click()
              }
              onDragOver={!isDisabled ? handleDragOver : undefined}
              onDragLeave={!isDisabled ? handleDragLeave : undefined}
              onDrop={!isDisabled ? handleDrop : undefined}
            >
              <input
                ref={ref}
                id="file-upload-input"
                type="file"
                hidden
                onChange={handleFileChange}
                accept={acceptedTypes?.join(',')}
                multiple
                disabled={isDisabled}
                data-testid="file-upload-input"
                data-guiding-id={guidingId}
              />
              <Typography className={classes.inlineText}>
                <span className={classes.uploadText}>
                  {t('common.upload-file')}
                </span>
                <span className={classes.dragDropText}>
                  {t('common.drag-and-drop')}
                </span>
              </Typography>
              {description && (
                <Typography className={classes.descriptionText}>
                  {description}
                </Typography>
              )}
            </Box>

            {error && (
              <FormHelperText className={classes.helperTextError}>
                {helperText}
              </FormHelperText>
            )}
            {auxHelperText ? (
              <FormHelperText>{auxHelperText}</FormHelperText>
            ) : null}
          </>
        ) : null}

        {fileData.length > 0 && (
          <Box className={classes.fileList}>
            {fileData.map((file, index) => (
              <Box key={index} className={classes.fileItem}>
                <Typography className={classes.fileName}>
                  {file.file_name}
                </Typography>
                {showDeleteOption ? (
                  <IconButton
                    className={classes.fileDeleteButton}
                    aria-label="delete file"
                    onClick={() => {
                      setFileToDeleteIndex(index)
                      setDeleteModalOpen(true)
                    }}
                  >
                    <TrashCan />
                  </IconButton>
                ) : null}
              </Box>
            ))}
          </Box>
        )}

        <ActionModal
          open={isDeleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          title={t('common.delete-file')}
          buttons={[
            {
              label: t('common.cancel'),
              color: 'secondary',
              onClick: () => setDeleteModalOpen(false),
            },
            {
              label: t('common.yes-delete'),
              color: 'primary',
              onClick: handleDeleteFile,
            },
          ]}
        >
          <Typography variant="body2">
            {t('common.modal.are-you-sure-like-to-delete')}{' '}
            <Typography component="span" fontWeight="bold">
              {fileData[fileToDeleteIndex ?? 0]?.file_name}
            </Typography>
            ? {t('common.modal.this-cannot-be-undone')}
          </Typography>
        </ActionModal>
      </Box>
    )
  }
)
