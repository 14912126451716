import { ApiService, App } from '../../commons'
import { mapResult } from '../../hooks/mapResult'
import { paginationQuery } from '../../hooks/paginationQuery'
import { EftRoutingNumber } from '../../schemas/types'

export class EftRoutingNumberService extends ApiService<EftRoutingNumber> {}

declare module '../../commons' {
  interface ServiceTypes {
    'eft-routing-numbers': EftRoutingNumberService
  }
}

export const eftRoutingNumbers = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'eft-routing-numbers',
    new EftRoutingNumberService(connection.service('eft-routing-numbers'), app),
    {
      methods: ['find', 'get', 'create', 'update', 'patch', 'remove'],
    }
  )

  app.service('eft-routing-numbers').hooks({
    around: {
      all: [paginationQuery(), mapResult()],
    },
  })
}
