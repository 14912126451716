import axios, { AxiosResponse, HttpStatusCode } from 'axios'

export const resetAuthorizationTimerEvent = new Event(
  'FTP_RESET_AUTHORIZATION_TIMER'
)

axios.interceptors.request.use((config) => {
  config.headers['access-token'] = localStorage.getItem('token')
  return config
})

axios.interceptors.response.use((response: AxiosResponse) => {
  if (response.status != HttpStatusCode.Unauthorized) {
    window.dispatchEvent(resetAuthorizationTimerEvent)
  }
  return response
})

export default axios
