import { ApiService, App } from '../../commons'
import { Batch, TerminalTransactionResponse } from '../../schemas/types'

export class BatchesService extends ApiService<Batch> {
  async close(batchID: string): Promise<TerminalTransactionResponse> {
    return this.apiRequest({
      url: `${batchID}/settle`,
      method: 'PUT',
    })
  }
}

declare module '../../commons' {
  interface ServiceTypes {
    batches: BatchesService
  }
}

export const batches = (app: App) => {
  const connection = app.get('connection')

  app.use('batches', new BatchesService(connection.service('batches'), app))
}
